import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { Observable, map } from "rxjs";
import { TeamRoleData } from "../data/team.model";

@Injectable({
  providedIn: "root",
})
export class TeamRoleService {
  constructor(private http: BackendHttpClient) {}

  fetchRoles(params: Record<string, string>): Observable<TeamRoleData[]> {
    return this.http
      .get(`teams/roles`, { params })
      .pipe(map((response: any) => response.result.team_roles));
  }
}
