import { Injectable } from "@angular/core";

@Injectable()
export class DateManagementService {
  public formatDateForAPI(date, time?) {
    if (!date) {
      return date;
    }

    const dates = date.split("-");
    if (dates[0].length > 3) {
      const dateObj = new Date(date);
      if (time) {
        dateObj.setMinutes(time.minute);
        dateObj.setHours(time.hour);
      }
      return dateObj.toISOString();
    }

    return new Date(
      dates[2] + "-" + dates[1] + "-" + dates[0] + " " + this.setTime(time)
    ).toISOString();
  }

  public setTime(date) {
    if (!date) {
      return "";
    }
    return (
      (date.hour < 10 ? "0" + date.hour : date.hour) +
      ":" +
      (date.minute < 10 ? "0" + date.minute : date.minute) +
      ":00"
    );
  }

  public formatDateMaterialInput(dateInput: string) {
    if (dateInput) {
      const date = new Date(dateInput);
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() - date.getTimezoneOffset()
      );
      return new Date(newDate).toISOString().split("T")[0];
    }
    return null;
  }
}
