import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AccountSelectors } from "@api/account/selectors";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { Store } from "@ngrx/store";
import { catchError, combineLatest, filter, map, of, timeout } from "rxjs";

const TIMEOUT_PERIOD = 3000;

@Injectable()
export class ClientGuard {
  constructor(
    private router: Router,
    private store: Store,
    private authorization: AuthorizationService
  ) {}

  canActivate() {
    return combineLatest([
      this.store.select(AccountSelectors.selectLoaded),
      this.authorization.$isClient(),
      this.authorization.$isExpat(),
    ]).pipe(
      filter(([loaded]) => loaded === true),
      timeout(TIMEOUT_PERIOD),
      map(([_, isClient, isExpat]) => {
        if (isClient || isExpat) {
          return true;
        }

        throw new Error();
      }),
      catchError((error) => of(this.router.createUrlTree(["/login"])))
    );
  }
}
