import { Injectable } from "@angular/core";
import { Breadcrumb } from "@models/breadcrumb";
import { BehaviorSubject } from "rxjs";
import { AssignmentData } from "@api/assignment";
import { RFQData } from "@api/rfq";

@Injectable()
export class BreadcrumbService {
  private breadcrumbsSource = new BehaviorSubject<Breadcrumb[] | null>(null);
  breadcrumbsChanges$ = this.breadcrumbsSource.asObservable();

  changeBreadcrumbs(breadcrumbs: Breadcrumb[] | null): void {
    this.breadcrumbsSource.next(breadcrumbs);
  }

  setForTaskReminders(caseId, taskId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: taskName },
      { url: null, label: "reminders" },
    ];
  }

  setForTaskExpenses(caseId, taskId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: taskName },
      { url: null, label: "expenses" },
    ];
  }

  setForPlans() {
    return [
      { url: "/billing", label: "Billing" },
      { url: "/billing/plans", label: "Plans" },
    ];
  }

  setForBillingInfo() {
    return [
      { url: "/billing", label: "Billing" },
      { url: "/billing/billing-info", label: "Billing Info" },
    ];
  }

  setForCustomReports(): Breadcrumb[] {
    return [
      { url: "/reports", label: "Reports" },
      { url: "reports/custom", label: "Custom" },
    ];
  }

  setForCasesReports(): Breadcrumb[] {
    return [
      { url: "/reports", label: "Reports" },
      { url: "reports/cases", label: "Cases" },
    ];
  }

  setForFinancialReports(): Breadcrumb[] {
    return [
      { url: "/reports", label: "Reports" },
      { url: "reports/financial", label: "Financial" },
    ];
  }

  setForMilestoneReports(): Breadcrumb[] {
    return [
      { url: "/reports/financial", label: "Reports" },
      { url: "reports/milestone", label: "Milestone reports" },
    ];
  }

  setForProvider(provider, label): Breadcrumb[] {
    return [
      { url: "/provider", label: "provider" },
      { url: "/provider/" + provider.id, label: provider.company_name },
      { url: null, label: label },
    ];
  }

  setStatic(label: string, url: string): Breadcrumb[] {
    return [{ url: url, label: label }];
  }

  setMyAccount(): Breadcrumb[] {
    return [
      { url: "/company-users", label: "company users" },
      { url: null, label: "my account" },
    ];
  }

  setCompanySettings(): Breadcrumb[] {
    return [
      { url: null, label: "company settings" },
      { url: null, label: "details" },
    ];
  }

  setCompanyMilestones(): Breadcrumb[] {
    return [
      { url: "/settings", label: "company settings" },
      { url: null, label: "Milestones" },
    ];
  }
  setCompanyUser(username: string, page?: string): Breadcrumb[] {
    return [
      { url: "/company-users", label: "company users" },
      { url: null, label: username },
      { url: null, label: page },
    ];
  }

  setForOrder(order, label): Breadcrumb[] {
    return [
      { url: "/orders", label: "orders" },
      {
        url: "/orders/" + order.identity_number,
        label: "Order no. " + order.identity_number,
      },
      { url: null, label: label },
    ];
  }

  setForExpat(expat, label?): Breadcrumb[] {
    if (label) {
      return [
        { url: "/expats", label: "employees" },
        { url: "/expats/" + expat.id, label: expat.user.name },
        { url: null, label: label },
      ];
    }

    return [
      { url: "/expats", label: "employees" },
      { url: "/expats/" + expat.id, label: expat.user.name },
    ];
  }

  setForClient(client, label: string): Breadcrumb[] {
    return [
      { url: "/clients", label: "clients" },
      { url: "/clients/" + client.id, label: client.company_name },
      { url: null, label: label },
    ];
  }

  setForDocuments(): Breadcrumb[] {
    return [{ url: "/documents", label: "documents" }];
  }

  setForCase(caseId, label: string): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: null, label: label },
    ];
  }

  setForTaxCalendar(): Breadcrumb[] {
    return [
      { url: "/tax-calendar", label: "Tax Calendar" },
      { url: "/tax-calendar/" + "/calendar", label: "Calendar" },
    ];
  }

  setForBenefitPackage(packageId): Breadcrumb[] {
    return [
      { url: "/benefits" + "/packages", label: "packages" },
      { url: "/benefits" + "/packages", label: "list" },
      {
        url: "/benefits" + "/packages" + packageId,
        label: "Package " + packageId,
      },
    ];
  }

  setForSubTask(caseId, taskId, subtaskName): Breadcrumb[] {
    return [
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: "/cases/" + caseId + "/task/" + taskId, label: "task " + taskId },
      { url: null, label: subtaskName },
    ];
  }

  setForTask(caseId, taskName): Breadcrumb[] {
    return [
      { url: "/cases", label: "cases" },
      { url: "/cases/" + caseId, label: "case " + caseId },
      { url: null, label: taskName },
    ];
  }

  setForRFQ(item: RFQData, label: string): Breadcrumb[] {
    const rfqLabel = item.request_type;
    return [
      { url: "/rfq", label: "RFQs & Bids" },
      { url: "/rfq/" + item.id, label: rfqLabel + " #" + item.id },
      { url: null, label: label },
    ];
  }

  setForList(url: string, label: string): Breadcrumb[] {
    return [
      { url: url, label: label },
      { url: null, label: "list" },
    ];
  }

  setForTemplateView(url: string, label: string): Breadcrumb[] {
    return [
      { url: url, label: label },
      { url: null, label: "view" },
    ];
  }

  setForAccommodationDetails(): Breadcrumb[] {
    return [
      { url: "/accommodation/details", label: "Temp Housing" },
      { url: null, label: "Details" },
    ];
  }

  setForAccommodation(): Breadcrumb[] {
    return [{ url: "/accommodation", label: "Temp Housing" }];
  }

  setForAssignment(assignment: AssignmentData, label): Breadcrumb[] {
    return [
      { url: "/assignments", label: "assignments" },
      { url: "/assignments/" + assignment.id, label: assignment.expat_name },
      { url: null, label: label },
    ];
  }

  setForCompanySettings(label: string): Breadcrumb[] {
    return [
      {
        url: "/clients/company-settings",
        label: "company settings",
      },
      { url: null, label: label },
    ];
  }

  setForOffer(offer, label: string): Breadcrumb[] {
    return [
      { url: "/processes", label: "internal processes" },
      { url: "/processes/" + offer.id, label: offer.title },
      { url: null, label: label },
    ];
  }

  setForCostProjection(label: string): Breadcrumb[] {
    return [{ url: "/cost-projection", label: label }];
  }
}
