import { Inject, Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { BASE_API_URL } from "../base-api-url.token";
import { Router } from "@angular/router";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject(BASE_API_URL) private apiURL: string,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.router.createUrlTree([this.apiURL, request.url]).toString();
    return next.handle(
      request.clone({
        url: this.apiURL + request.url,
      })
    );
  }
}
