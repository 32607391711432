import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { ValueList } from "@modules/shared/models/value-list.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
/**
 * @deprecated use instead src/app/api/documents/services/documents.service.ts
 */
@Injectable()
export class DocumentsService {
  private docsUrl = "files";
  private ordersUploadUrl = "billing/orders";
  private clientsUploadUrl = "entities/clients";
  private providersUploadUrl = "entities/providers";
  private expatsUploadUrl = "entities/expats";
  public docTypeInvoice = "invoices";

  constructor(private http: HttpClient) {}

  // GET docs by entity and ID
  getDocuments(
    entityType,
    entityId,
    docType,
    params: ValueList<string | number> = {}
  ): Observable<any> {
    if (!params.include) {
      params.include = "filters;details";
    }
    return this.http.get<any>(
      environment.gateway_endpoint +
        entityType +
        "/" +
        entityId +
        "/" +
        this.docsUrl +
        "/" +
        docType,
      {
        params: params,
      }
    );
  }

  // GET statuses for doc upload
  getStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "files/statuses");
  }

  // GET statuses for invoice upload
  getFilterOrderInvoicesStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "invoice/filter-order-invoices-statuses"
    );
  }

  // Upload by doc type
  uploadFiles(id, fileType, obj, entityType): Observable<any> {
    let url;
    const formData: FormData = new FormData();

    if (obj.file && obj.file.length > 0) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    switch (entityType) {
      case "clients":
        url = this.clientsUploadUrl;
        break;
      case "providers":
        url = this.providersUploadUrl;
        break;
      case "orders":
        url = this.ordersUploadUrl;
        break;
      case "expats":
        url = this.expatsUploadUrl;
        break;
    }

    return this.http
      .post<any>(
        environment.gateway_endpoint + url + "/" + id + "/" + fileType,
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadImage(id, fileType, obj, entityType, docId): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    if (docId) {
      formData.append("file_id", docId);
      formData.append("status", "updated");
    }

    const url = entityType + "/" + id + "/update-profile-picture";

    return this.http
      .post<any>(environment.gateway_endpoint + url, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadProfilePicture(id, obj, entityType): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    if (obj.status) {
      formData.append("status", obj.status);
    }
    if (obj.name) {
      formData.append("name", obj.name);
    }

    const url = entityType + "/" + id + "/update-profile-picture";

    return this.http
      .post<any>(environment.gateway_endpoint + url, formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Delete doc

  deleteDocument(id): Observable<any> {
    return this.http
      .delete<any>(environment.gateway_endpoint + "files/" + id, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Download Zip Invoices

  downloadZipInvoices(id): Observable<any> {
    const options: any = {
      headers: new HttpHeaders({ "Content-Type": "application/zip" }),
      responseType: "arraybuffer",
    };
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.ordersUploadUrl +
        "/" +
        id +
        "/invoices/download-zip",
      options
    );
  }
}
