import { HttpBackend, HttpClient, HttpInterceptor } from "@angular/common/http";
import { Injectable, InjectionToken, Injector } from "@angular/core";
import { CustomInterceptorHandler } from "./custom-intercept";

export const BACKEND_API_HTTP_INTERCEPTORS = new InjectionToken<
  HttpInterceptor[]
>("BACKEND_API_HTTP_INTERCEPTORS");

@Injectable({
  providedIn: "root",
})
export class BackendHttpClient extends HttpClient {
  constructor(backend: HttpBackend, injector: Injector) {
    super(
      new CustomInterceptorHandler(
        backend,
        injector,
        BACKEND_API_HTTP_INTERCEPTORS
      )
    );
  }
}
