import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssignmentMapperService } from "@api/assignment/services/assignment-mapper.service";

import { AssignmentsService } from "./services";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AssignmentsService, AssignmentMapperService],
})
export class AssignmentsModule {}
