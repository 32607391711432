import { Injectable } from "@angular/core";
import { TemplateElementsSections } from "../models";

@Injectable({
  providedIn: "root",
})
export class TemplateFieldsMapperService {
  mapOne(input: any): TemplateElementsSections {
    return {
      ...input,
      active: true,
      elements: input.elements ? this.mapMany(input.elements) : null,
    };
  }

  mapMany(input: any[]): TemplateElementsSections[] {
    return input.map((i) => this.mapOne(i));
  }

  searchTemplateElements(
    elements: TemplateElementsSections[],
    keyword: string
  ): void {
    elements.map((element) => {
      if (
        element.elements &&
        element.elements.length > 0 &&
        element.slug !== "formatting" &&
        element.slug !== "search" &&
        element.slug !== "basic"
      ) {
        element.elements.find((el) => {
          el.active = el.slug.includes(keyword);
        });
      }
    });
  }
}
