import { createAction, props } from "@ngrx/store";

export const loaded = createAction("[Auth Update] Loaded");
export const authorized = createAction(
  "[Auth Update] Authorized",
  props<{ token: string }>()
);
export const logout = createAction("[Auth Update] Logout");
export const unauthenticated = createAction("[Auth Update] Unauthenticated");
export const reset = createAction("[Auth Update] Reset");
