export interface AssignmentData {
  id: number;
  client_id: number;
  expat_id: number;
  expat_name: string;
  home_country: string;
  home_city?: string;
  home_manager_id: number;
  home_manager_name: string;
  home_manager_email: string;
  host_country: string;
  host_manager_id: number;
  host_manager_name: string;
  host_manager_email: string;
  host_city: string;
  host_office_id: number;
  host_office_name: string;
  type: string;
  status: string;
  start_date: string;
  end_date: string;
  purpose: string;
  notes: string;
  created_at: string;
  provider_id?: number;
  company_name?: string;
  country_code?: string;
  orders?: string;
  order_value?: string;
  currency_code?: string;
  assignment_receiver?: AssignmentReceiver[];
  status_class_name?: string;
}

export interface AssignmentReceiver {
  id: number;
  assignment_id: number;
  uuid: string;
  receiver_email: string;
  status: string;
  comment: string;
  first_name: string;
  last_name: string;
  author_name: string;
}
