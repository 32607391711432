import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class RFQSService {
  private serviceCategoriesUrl = "offers/service-categories";

  constructor(private http: HttpClient) {}

  createRFQAndBid(rfq): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/create",
      rfq,
      httpOptions
    );
  }

  uploadDocumentToRFQ(rfqId, offerRequestId, file): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(
      environment.gateway_endpoint +
        "rfqs/" +
        rfqId +
        "/offer-requests/" +
        offerRequestId +
        "/documents",
      formData
    );
  }

  // Update RFQ/Bid status
  updateStatusBulk(status, client_id, rfq_id, reason = ""): Observable<any> {
    let body: any = { status: status, client_id: client_id };
    if (reason) {
      body = { status: status, client_id: client_id, declined_reason: reason };
    }
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/" + rfq_id + "/change-status",
      body,
      httpOptions
    );
  }

  // GET Service Categories
  getServiceCategories(params = {}): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + this.serviceCategoriesUrl, {
        params: params,
      })
      .pipe(map((response) => response?.result?.items));
  }

  // Get RFQ quotation offers
  getAcceptedOffers(params): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "finalize-rfqs",
      params,
      httpOptions
    );
  }

  // Add to cart
  addRFQtoCart(params): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "rfqs/add-to-cart",
      params,
      httpOptions
    );
  }
}
