import { Component, Input } from "@angular/core";
import { WorkflowModel } from "@models/workflow";

@Component({
  selector: "app-task-timeline",
  templateUrl: "./task-timeline.component.html",
  styleUrls: ["./task-timeline.component.scss"],
})
export class TaskTimelineComponent {
  @Input()
  public tasks: WorkflowModel[];

  @Input()
  public maximumDuration: number;

  public fakeArray(number: number) {
    return new Array(number).fill(1, number);
  }

  public showSubtasks(workflow: WorkflowModel) {
    if (workflow.isSubtasksAvailable === undefined) {
      workflow.isSubtasksAvailable = true;
    } else {
      workflow.isSubtasksAvailable = !workflow.isSubtasksAvailable;
    }
  }

  public getTimelineColor(
    index: number,
    startDay: number,
    endDay: number,
    isChild: boolean
  ) {
    if (index === startDay && startDay === endDay) {
      return isChild
        ? "orange-background-color-single-child-element"
        : "orange-background-color-single-element";
    }
    if (index === startDay) {
      return isChild
        ? "orange-background-color-first-child-element"
        : "orange-background-color-first-element";
    }
    if (index === endDay) {
      return isChild
        ? "orange-background-color-last-child-element"
        : "orange-background-color-last-element";
    }
    if (index > startDay && index < endDay) {
      return isChild
        ? "orange-background-color-child"
        : "orange-background-color";
    }
  }
}
