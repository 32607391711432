import { environment } from "@environment/environment";

const typeCache: { [label: string]: boolean } = {};
export function type<T>(label: T | ""): T {
  if (environment.production) {
    return <T>label;
  }
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}
