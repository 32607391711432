import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  RfqDocumentMapperService,
  RfqEligibleProviderMapperService,
  RfqMapperService,
  RFQService,
} from "@api/rfq/services";
import { StoreModule } from "@ngrx/store";
import { fromRfq } from "@api/rfq/reducers";
import { EffectsModule } from "@ngrx/effects";
import { RfqEffects } from "@api/rfq/effects/rfq.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([RfqEffects]),
    StoreModule.forFeature(fromRfq.rfqFeatureKey, fromRfq.reducer),
  ],
  providers: [
    RFQService,
    RfqMapperService,
    RfqDocumentMapperService,
    RfqEligibleProviderMapperService,
  ],
})
export class RfqModule {}
