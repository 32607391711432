export interface CartState {
  cartItems: number;
}

export const cartState: CartState = {
  cartItems: 0,
};

export interface MessagesState {
  messagesNum: number;
}

export const messagesState: MessagesState = {
  messagesNum: 0,
};

export interface FavState {
  favNum: number;
}

export const favState: FavState = {
  favNum: 0,
};

export interface AppState {
  cart: CartState;
  messages: MessagesState;
  favourites: FavState;
}
