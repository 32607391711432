import { Injectable } from "@angular/core";
import { TaskActions } from "@api/case-tasks/actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, distinct, mergeMap } from "rxjs";
import { CaseActions } from "../actions";
import { CasesService } from "../services";

@Injectable()
export class CaseEffects {
  onSelect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.selectCase),
      distinct((a) => a.caseId),
      mergeMap(({ caseId }) => [CaseActions.fetchCase({ caseId })])
    );
  });

  onFetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.fetchCase),
      concatMap(({ caseId }) => this.caseService.fetchCase(caseId)),
      mergeMap((caseData) => [
        CaseActions.fetchCaseSuccess({ case: caseData }),
      ]),
      catchError((error) => [CaseActions.fetchCaseError({ error })])
    );
  });

  onCaseServiceCreated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.requestCreateServicesSuccess),
      mergeMap(({ case: caseData }) => [
        TaskActions.loadCaseTasks({ caseId: caseData.id }),
      ])
    );
  });

  onAssignManagerSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.requestAssignManagerSuccess),
      mergeMap(({ case: caseData }) => [
        CaseActions.fetchCase({ caseId: caseData.id }),
        TaskActions.loadCaseTasks({ caseId: caseData.id }),
      ])
    );
  });

  constructor(private actions$: Actions, private caseService: CasesService) {}
}
