import { Injectable } from "@angular/core";

@Injectable()
export class OrderHelper {
  expatsOnOrder(order) {
    return order.details.reduce((sum, value) => (sum += value.quantity), 0);
  }

  assignedExpatsOnOrder(order) {
    return order.details.reduce(
      (sum, detail) => (sum += detail.expats.length),
      0
    );
  }

  remainingExpatsToAssign(order) {
    return this.expatsOnOrder(order) - this.assignedExpatsOnOrder(order);
  }
}
