import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { Cart } from "@models/cart";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const cartUrl = "billing/cart";
const checkoutUrl = "billing/checkout";

@Directive()
@Injectable()
export class CartService {
  constructor(private http: HttpClient) {}

  private request: Observable<any>;
  public cart: Cart;

  @Output() changeCart: EventEmitter<any> = new EventEmitter();

  _clear() {
    this.request = null;
    this.cart = null;
  }

  addToCart(params): Observable<any> {
    this._clear();

    return this.http
      .post<any>(environment.gateway_endpoint + cartUrl, params, httpOptions)
      .pipe(
        map((model) => {
          this.changeCart.emit(model.result);
          return model;
        })
      );
  }

  getCartItems(): Observable<any> {
    if (this.cart) {
      return of(this.cart);
    }

    if (this.request) {
      return this.request;
    }

    this.request = this.http
      .get<any>(environment.gateway_endpoint + cartUrl)
      .pipe(
        map((data) => {
          this.cart = data.result;
          this.changeCart.emit(data.result);
          return data.result;
        })
      );

    return this.request;
  }

  updateItemQuantity(id, qty): Observable<any> {
    this._clear();

    return this.http
      .put<any>(
        environment.gateway_endpoint + cartUrl + "/" + id,
        qty,
        httpOptions
      )
      .pipe(
        map((model) => {
          this.changeCart.emit(model.result);
          this.cart = model.result;
          return model;
        })
      );
  }

  removeCartItem(id): Observable<any> {
    this._clear();

    return this.http
      .delete<any>(
        environment.gateway_endpoint + cartUrl + "/" + id,
        httpOptions
      )
      .pipe(
        map((model) => {
          this.cart = model.result;
          this.changeCart.emit(model.result);
          return model;
        })
      );
  }

  deleteCart(): Observable<any> {
    this._clear();

    return this.http.delete<any>(environment.gateway_endpoint + cartUrl).pipe(
      map((model) => {
        this.changeCart.emit(null);
        return model;
      })
    );
  }

  checkout(params): Observable<any> {
    this._clear();

    return this.http
      .post<any>(
        environment.gateway_endpoint + checkoutUrl,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          this.changeCart.emit(null);
          return model;
        })
      );
  }

  /**
   * Billing APIs
   */

  addBillingAddress(params, id, entityType = "entities"): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          entityType +
          "/" +
          id +
          "/billing-details",
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateBillingAddress(
    params,
    entity_id,
    billing_id,
    entityType = "entities"
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          entityType +
          "/" +
          entity_id +
          "/billing-details/" +
          billing_id,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getBillingAddresses(id, entityType = "entities"): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + entityType + "/" + id + "/billing-details"
    );
  }

  deleteBillingAddresses(entity_id, billing_id, entityType): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          entityType +
          "/" +
          entity_id +
          "/billing-details/" +
          billing_id
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
  /**
   * End Billing APIs
   */

  // Get user Agreements

  getUserAgreements(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "billing/cart/user-agreements"
    );
  }
}
