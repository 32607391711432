import { Component, OnDestroy, Input, Inject } from "@angular/core";
import { MarketService } from "@services/market.service";
import { AlertService } from "@services/alert.service";
import { DOCUMENT } from "@angular/common";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnDestroy {
  email;
  @Input() ercLandingpages;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private service: MarketService,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigateToFb() {
    this.document.defaultView.open(
      "https://www.facebook.com/xpathglobal-108908723844125/"
    );
  }
  navigateToIn() {
    this.document.defaultView.open(
      "https://www.linkedin.com/company/xpathglobal"
    );
  }

  newsletterSubscribe() {
    this.service
      .newsletterSubscribe({ email: this.email })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res && res.result.message) {
            this.alertService.success(res.result.message);
          }
          this.email = null;
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }
}
