import { CaseTaskData } from "@api/case-tasks";

export interface TimelineCaseTask extends CaseTaskData {
  active?: boolean;
  startDateDay?: number;
  endDateDay?: number;
  expireIn?: number;
  isNextMonthOverlapped?: boolean;
  isPreviousMonthOverlapped?: boolean;
  isSubTask?: boolean;
  isCurrentMonthSubTaskActive: boolean;
  subtasks: Array<TimelineCaseTask>;
  activeStatus?: string;
}
