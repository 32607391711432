import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { tap } from "rxjs/operators";
import { UserData } from "@api/users/models/user.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ApiNotificationService {
  public unreadNotifications: number;

  private notificationsUrl = "notifications";

  constructor(private http: HttpClient) {}

  // GET Clients
  getNotifications(params = {}, user: UserData): Observable<any> {
    if (user) {
      return this.http
        .get<any>(environment.gateway_endpoint + this.notificationsUrl, {
          params: params,
        })
        .pipe(
          tap((data) => {
            if (data.success) {
              this.unreadNotifications = data.result.total_unread_notifications;
            }
          })
        );
    }
  }

  // PATCH Mark notifications as seen
  markAsRead(notification_id?): Observable<any> {
    let body = {};
    if (notification_id) {
      body = { notification_id: notification_id };
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + this.notificationsUrl,
      body,
      httpOptions
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.notificationsUrl +
        "/status-transitions"
    );
  }

  // Archive Status Bulk
  archiveNotification(notificationIds): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + this.notificationsUrl + "/archive",
      { notification_ids: notificationIds },
      httpOptions
    );
  }
}
