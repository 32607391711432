export enum RFQStatusID {
  OPEN = 1,
  FINALIZED = 2,
  CANCELED = 3,
  DRAFT = 4,
  CLOSED = 5,
  EXTENDED = 6,
  PUBLISHED = 7,
}

export enum RFQType {
  BID = "bid",
  RFQ = "rfq",
}

export enum RFQProviderStatus {
  OFFERS_PLACED = "offers_placed",
  DECLINED = "decline",
}
