import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class ScrollService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  scrollToTop() {
    this.document.defaultView.scroll(0, 0);
  }

  smoothScrollTop() {
    this.document.defaultView.scrollTo({ top: 0, behavior: "smooth" });
  }

  scrollToElementById(elementId: string) {
    this.document.querySelector("#" + elementId)?.scrollIntoView();
  }
}
