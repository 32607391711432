import { Injectable } from "@angular/core";
import { ResourcePermissionData } from "@api/users/models/resource-permission.model";

@Injectable()
export class ResourcePermissionMapperService {
  mapOne(input: any): ResourcePermissionData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): ResourcePermissionData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any) {
    return { ...input };
  }
}
