import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AccountSelectors } from "@api/account/selectors";
import { AuthorizationService } from "@api/users/services/authorization.service";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@services/alert.service";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthorizationGuard {
  constructor(
    private _authService: AuthorizationService,
    private router: Router,
    private translate: TranslateService,
    private alertService: AlertService,
    private store: Store
  ) {}

  readonly mktpViewPermission = {
    action: PermissionActionEnum.VIEW,
    resource: PermissionResourceEnum.MARKETPLACE,
    subResource: PermissionSecondaryResourceEnum.DETAILS,
  };

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.shallowEqual(next.data, this.mktpViewPermission)) {
      return this.store.select(AccountSelectors.selectAccountState).pipe(
        map((account) => {
          if (!account.user) {
            return true;
          }

          const permission = `${next.data.action} ${next.data.resource} ${next.data.secondaryResource}`;

          if (account.user?.permissions?.indexOf(permission) > -1) {
            return true;
          } else {
            this.router.navigate["/dashboard"];
          }
        })
      );
    }

    return this._authService
      .$can(next.data.action, next.data.resource, next.data.subResource)
      .pipe(
        tap((can) => {
          if (!can) {
            this.translate
              .get("GENERALS.PERMISSION-DO-NOT-ALLOW")
              .subscribe((translation) => {
                this.alertService.error(translation);
              });

            this.router.navigate(["/dashboard"], {
              queryParams: {},
            });
          }
        })
      );
  }

  private shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }
}
