import { createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { CaseData as Case } from "../models/case.model";
import * as CaseActions from "../actions/case.actions";

export const casesFeatureKey = "cases";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends EntityState<Case> {
  // additional entities state properties
  selectedCaseId: null | number;
}

export const adapter: EntityAdapter<Case> = createEntityAdapter<Case>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedCaseId: null,
});

export const reducer = createReducer(
  initialState,
  on(CaseActions.addCase, (state, action) =>
    adapter.addOne(action.case, state)
  ),
  on(CaseActions.selectCase, (state, { caseId }) => ({
    ...state,
    selectedCaseId: caseId,
  })),
  on(CaseActions.fetchCaseSuccess, (state, action) =>
    adapter.setOne(action.case, state)
  ),
  on(CaseActions.upsertCase, (state, action) =>
    adapter.upsertOne(action.case, state)
  ),
  on(CaseActions.addCases, (state, action) =>
    adapter.addMany(action.cases, state)
  ),
  on(CaseActions.upsertCases, (state, action) =>
    adapter.upsertMany(action.cases, state)
  ),

  on(CaseActions.updateCase, (state, action) =>
    adapter.updateOne(action.case, state)
  ),
  on(CaseActions.updateCases, (state, action) =>
    adapter.updateMany(action.cases, state)
  ),
  on(CaseActions.deleteCase, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(CaseActions.deleteCases, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(CaseActions.loadCases, (state, action) =>
    adapter.setAll(action.cases, state)
  ),
  on(CaseActions.clearCases, (state) => adapter.removeAll(state)),

  // UPDATE CASE
  on(CaseActions.requestUpdateCase, (state, action) => ({
    ...state,
    updating: true,
  })),
  on(CaseActions.requestUpdateCaseSuccess, (state, action) =>
    adapter.upsertOne(action.case, {
      ...state,
      updating: false,
    })
  ),
  on(CaseActions.requestUpdateCaseError, (state, action) => ({
    ...state,
    updating: false,
  })),
  // CREATE SERVICE CASE
  on(CaseActions.requestCreateServices, (state, action) => ({
    ...state,
    updating: true,
  })),
  on(CaseActions.requestCreateServicesSuccess, (state, action) =>
    adapter.upsertOne(action.case, {
      ...state,
      updating: false,
    })
  ),
  on(CaseActions.requestCreateServicesError, (state, action) => ({
    ...state,
    updating: false,
  })),
  // UPDATE CASE SERVICE
  on(CaseActions.requestUpdateService, (state, action) => ({
    ...state,
    updating: true,
  })),
  on(CaseActions.requestUpdateServiceSuccess, (state, action) => {
    const service = action.service;
    const caseData = state.entities[service.expat_case_id];
    if (!caseData) {
      return {
        ...state,
        updating: false,
      };
    }
    const caseServices = structuredClone(caseData.services).map(
      (caseService) => {
        if (caseService.id !== action.service.id) {
          return caseService;
        }
        return {
          ...caseService,
          ...action.service,
        };
      }
    );

    return adapter.updateOne(
      {
        id: caseData.id,
        changes: { services: caseServices },
      },
      {
        ...state,
        updating: false,
      }
    );
  }),
  on(CaseActions.requestUpdateServiceError, (state, action) => ({
    ...state,
    updating: false,
  }))
);

export const getSelectedCaseId = (state: State) => state.selectedCaseId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of case ids
export const selectCaseIds = selectIds;

// select the dictionary of case entities
export const selectCaseEntities = selectEntities;

// select the array of cases
export const selectAllCases = selectAll;

// select the total case count
export const selectCaseTotal = selectTotal;
