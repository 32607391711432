import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
  selector: "[toggle-filters]",
})
export class ToggleFiltersDirective {
  @HostBinding("class.hide") isOpen = false;

  @HostListener("click") toggleOpen($event) {
    this.isOpen = !this.isOpen;
  }
}
