import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { KomboIntegration } from "../models/kombo-integration.model";

@Injectable({
  providedIn: "root",
})
export class KomboService {
  constructor(protected http: BackendHttpClient) {}

  listIntegrations(): Observable<KomboIntegration[]> {
    return this.http
      .get<any>("integrations/kombo", {})
      .pipe(map((res: any) => res.items));
  }

  initIntegration(): Observable<string> {
    return this.http.post("integrations/kombo/init", {}).pipe(
      map((res: any) => {
        return res?.data?.link;
      })
    );
  }

  activateIntegration(token): Observable<KomboIntegration> {
    return this.http.post<any>("integrations/kombo/activate", { token });
  }
}
