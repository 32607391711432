import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from "@angular/material/core";
import { MomentModule } from "ngx-moment";
import { MatDatepickerModule } from "@angular/material/datepicker";

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@NgModule({
  imports: [
    CommonModule,
    MomentModule,
    MomentDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
  ],
  exports: [
    MomentModule,
    MomentDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
  ],
  declarations: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-gb" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class DateModule {}
