/**
 * Temporary file to contain possible configuration of the app.
 */

export const marketplace = {
  dashboard: true,
  welcome: false,
  internal_processes: false,
  rfqs: true,
  "tax-calendar": false,
  processes: false,
  benefits: false,
  expats: true,
  assignments: false,
  orders: true,
  cases: false,
  documents: false,
  providers: true,
  reports: false,
  roles: false,
  users: false,
  billing: false,
  settings: false,
  messaging: true,
  templates: false,
  "sub-messaging-conversations": true,
  "sub-messaging-notifications": true,
  accommodation: true,
};

export const saas = {
  dashboard: true,
  welcome: true,
  internal_processes: true,
  rfqs: false,
  processes: true,
  benefits: true,
  expats: true,
  assignments: true,
  "tax-calendar": true,
  orders: true,
  cases: true,
  documents: true,
  providers: true,
  reports: true,
  roles: true,
  users: true,
  billing: true,
  settings: true,
  messaging: false,
  templates: true,
  "sub-messaging-conversations": true,
  "sub-messaging-notifications": true,
  accommodation: true,
};

export const full = {
  dashboard: true,
  "expat-profile": true,
  welcome: true,
  internal_processes: true,
  rfq: true,
  "tax-calendar": true,
  processes: true,
  benefits: true,
  expats: true,
  assignments: true,
  orders: true,
  cases: true,
  tasks: true,
  documents: true,
  providers: true,
  reports: true,
  roles: false,
  users: true,
  billing: true,
  settings: true,
  messaging: true,
  forms: true,
  templates: true,
  teams: true,
  "cost-projection": true,
  "sub-messaging-conversations": true,
  "sub-messaging-notifications": true,
  accommodation: true,
};
