import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportsService } from "./services/reports.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ReportsService],
})
export class ReportsModule {}
