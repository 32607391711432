import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { InternalProcessMapperService } from "./services/internal-process-mapper.service";
import { InternalProcessesService } from "./services/internal-processes.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [InternalProcessesService, InternalProcessMapperService, DatePipe],
})
export class InternalProcessesModule {}
