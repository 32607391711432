import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { combineLatest, Observable, of } from "rxjs";
import { environment } from "@environment/environment";
import { ValueList } from "@modules/shared/models/value-list.model";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { map } from "rxjs/operators";
import { UsersService } from "./users.service";
import { UserStatus } from "@models/_statuses/user-statuses";
import { EntityTypeEnum } from "../_enums/entity-type-enum";
import { AccommodationService } from "@api/accommodation/services/accommodation.service";
import { UserData } from "@api/users/models/user.model";
import { Integration } from "@api/accommodation/models";
import { ExpatData } from "@models/expat";
import { UserEntityData } from "@api/account";

export interface AsideNavItem {
  name: string;
  icon?: string;
  label: string;
  badge?: Observable<any>;
  routerLink: any[];
  canDisplay: Array<Observable<boolean>>;
  children: AsideNavItem[];
  isDisabled: Array<Observable<boolean>>;
}
export interface AsideNavList {
  children: AsideNavItem[];
}
@Injectable({
  providedIn: "root",
})
export class AsideNavigationService {
  private menu: AsideNavList;
  private readonly enabledPages: ValueList<boolean>;
  isDisabled$: Observable<boolean>;

  constructor(
    public router: Router,
    public authService: AuthorizationService,
    private userService: UsersService,
    private accommodationService: AccommodationService
  ) {
    this.enabledPages = environment.enabled_pages || {};
    this.isDisabled$ = this.userService
      .getCurrentUser()
      .pipe(
        map(
          (user) =>
            user?.status_id !== UserStatus.active ||
            (user.entity_type != EntityTypeEnum.EXPAT &&
              (user?.entity_details as UserEntityData)?.company?.status_id !==
                UserStatus.active)
        )
      );
  }
  getMenu(): AsideNavList {
    if (this.menu) {
      return this.menu;
    }
    this.menu = {
      children: [
        {
          name: "dashboard",
          icon: "menu-icon-dashboard",
          label: "LEFT-NAVIGATION.DASHBOARD",
          routerLink: ["/dashboard"],
          canDisplay: [],
          children: [],
          isDisabled: [],
        },
        {
          name: "expat-profile",
          icon: "menu-icon-users",
          label: "LEFT-NAVIGATION.EXPAT-PROFILE",
          routerLink: ["/expats/profile"],
          canDisplay: [this.authService.$isExpat()],
          children: [],
          isDisabled: [],
        },
        {
          name: "expats",
          icon: "people",
          label: "LEFT-NAVIGATION.EXPATS",
          routerLink: ["/expats"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(PermissionResourceEnum.EXPAT),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "assignments",
          icon: "menu-icon-assignments",
          label: "LEFT-NAVIGATION.ASSIGNMENTS",
          routerLink: ["/assignments"],
          canDisplay: [
            this.authService.$hasResource(PermissionResourceEnum.ASSIGNMENT),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "orders",
          icon: "menu-icon-orders",
          label: "LEFT-NAVIGATION.ORDERS",
          routerLink: ["/orders"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(PermissionResourceEnum.ORDER),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "cases",
          icon: "menu-icon-cases",
          label: "LEFT-NAVIGATION.CASES",
          routerLink: ["/cases"],
          canDisplay: [
            this.authService.$hasResource(PermissionResourceEnum.CASE),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "tasks",
          icon: "menu-icon-tasks",
          label: "LEFT-NAVIGATION.TASKS",
          routerLink: ["/tasks"],
          canDisplay: [
            this.authService.$hasResource(PermissionResourceEnum.TASK),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "processes",
          icon: "menu-icon-processes",
          label: "LEFT-NAVIGATION.PROCESSES",
          routerLink: ["/processes"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(PermissionResourceEnum.SERVICE),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "benefits",
          icon: "menu-icon-benefits",
          label: "LEFT-NAVIGATION.BENEFITS",
          routerLink: ["/benefits"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(PermissionResourceEnum.BENEFIT),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "documents",
          icon: "menu-icon-documents",
          label: "LEFT-NAVIGATION.DOCUMENTS",
          routerLink: ["/documents"],
          canDisplay: [
            this.authService.$hasResource(PermissionResourceEnum.DOCUMENT),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "cost-projection",
          icon: "menu-icon-cost-projections",
          label: "LEFT-NAVIGATION.COST_PROJECTION",
          routerLink: ["/cost-projection"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(
              PermissionResourceEnum.COST_PROJECTION
            ),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "providers",
          icon: "menu-icon-providers",
          label: "LEFT-NAVIGATION.PROVIDERS",
          routerLink: ["/provider"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(PermissionResourceEnum.PROVIDER),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "rfq",
          icon: "menu-icon-rfq-bids",
          label: "LEFT-NAVIGATION.RFQ",
          routerLink: ["/rfq"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasResource(PermissionResourceEnum.RFQ),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "accommodation",
          icon: "menu-icon-temp-housing",
          label: "LEFT-NAVIGATION.ACCOMMODATION",
          routerLink: ["/accommodation"],
          canDisplay: [
            this.authService.$hasResource(PermissionResourceEnum.ACCOMMODATION),
            combineLatest([
              this.authService.$isClient(),
              this.authService.currentUser,
              this.accommodationService.integrations(),
            ]).pipe(
              map(
                ([isClient, currentUser, integrations]: [
                  boolean,
                  UserData,
                  Integration[]
                ]) => {
                  if (isClient) {
                    return true;
                  }

                  return (
                    !!(currentUser.entity_details as ExpatData).client_id &&
                    !!integrations.find(
                      (integration: Integration) => integration.self_serve
                    )
                  );
                }
              )
            ),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "templates",
          icon: "menu-icon-templates",
          label: "LEFT-NAVIGATION.TEMPLATE",
          routerLink: ["/templates"],
          canDisplay: [
            this.authService.$isClient(),
            combineLatest([
              this.authService.$hasResource(PermissionResourceEnum.TEMPLATE),
              this.authService.$hasResource(PermissionResourceEnum.FORM),
            ]).pipe(map(([a, b]) => a || b)),
          ],
          children: [
            {
              name: "templates",
              label: "LEFT-NAVIGATION.DOCUMENTS",
              routerLink: ["/templates/documents"],
              canDisplay: [
                this.authService.$isClient(),
                this.authService.$hasResource(PermissionResourceEnum.TEMPLATE),
              ],
              children: [],
              isDisabled: [this.isDisabled$],
            },
            {
              name: "forms",
              label: "LEFT-NAVIGATION.FORMS",
              routerLink: ["/templates/forms"],
              canDisplay: [
                this.authService.$isClient(),
                this.authService.$hasResource(PermissionResourceEnum.FORM),
              ],
              children: [],
              isDisabled: [this.isDisabled$],
            },
          ],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "reports",
          icon: "menu-icon-reports",
          label: "LEFT-NAVIGATION.REPORTS",
          routerLink: ["/reports/financial"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$hasSecondaryResource(
              PermissionResourceEnum.REPORTS,
              PermissionSecondaryResourceEnum.FINANCIAL
            ),
          ],
          children: [],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "users",
          icon: "menu-icon-users",
          label: "LEFT-NAVIGATION.USERS",
          routerLink: ["/users"],
          canDisplay: [this.authService.$isClient()],
          children: [
            {
              name: "users",
              label: "LEFT-NAVIGATION.USERS",
              routerLink: ["/users/company-users"],
              canDisplay: [
                this.authService.$isClient(),
                this.authService.$hasResource(
                  PermissionResourceEnum.COMPANY_USER
                ),
              ],
              children: [],
              isDisabled: [this.isDisabled$],
            },
            {
              name: "teams",
              label: "LEFT-NAVIGATION.TEAMS",
              routerLink: ["/users/teams"],
              canDisplay: [
                this.authService.$isClient(),
                this.authService.$hasResource(PermissionResourceEnum.TEAM),
              ],
              children: [],
              isDisabled: [this.isDisabled$],
            },
          ],
          isDisabled: [this.isDisabled$],
        },
        {
          name: "settings",
          icon: "menu-icon-settings",
          label: "LEFT-NAVIGATION.SETTINGS",
          routerLink: ["/clients/company-settings"],
          canDisplay: [
            this.authService.$isClient(),
            this.authService.$can(
              PermissionActionEnum.UPDATE,
              PermissionResourceEnum.CLIENT,
              PermissionSecondaryResourceEnum.DETAILS
            ),
          ],
          children: [],
          isDisabled: [],
        },
        {
          name: "tax-calendar",
          icon: "menu-icon-tax-calendar",
          label: "LEFT-NAVIGATION.TAX_CALENDAR",
          routerLink: ["/tax-calendar"],
          canDisplay: [this.authService.$isExpat()],
          children: [],
          isDisabled: [this.isDisabled$],
        },
      ],
    };
    return this.menu;
  }
  isPageEnabled(page: string): Observable<boolean> {
    return of(!!this.enabledPages[page]);
  }
}
