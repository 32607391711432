import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, timeout } from "rxjs/operators";
import { AuthSelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class NoAuthGuard {
  constructor(public store: Store, public router: Router) {}
  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(AuthSelectors.selectAuthState).pipe(
      filter(({ loaded }) => loaded),
      timeout(3000),
      map(({ token }) => {
        if (token) {
          return this.router.parseUrl("");
        }
        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}
