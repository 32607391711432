<!---->

<div class="custom-modal-wrapper" *ngIf="!isLoading">
  <div class="modal-title-custom">
    {{ "GENERALS.SERVICE-OFFER-ADDED-CART" | translate }}
  </div>

  <div class="modal-body-custom">
    <div class="cart-item-details add-to-cart-modal">
      <div
        (click)="goToOfferDetails(item.offer.id)"
        class="cart-item-details-img pointer"
      >
        <img
          *ngIf="item.offer.primary_image_path.length"
          src="{{ item.offer.primary_image_path }}"
          alt="img"
          onerror="this.src='../assets/images/online.png';"
          appLazyLoad
        />
        <img
          *ngIf="!item.offer.primary_image_path.length"
          src="../../../assets/images/footer-img.jpg"
          alt="img"
          appLazyLoad
        />
      </div>
      <div class="cart-item-details-left">
        <div
          (click)="goToOfferDetails(item.offer.id)"
          class="cart-item-details-title"
        >
          {{ item.offer.title }}
        </div>
        <div class="cart-item-details-desc">
          <!--<p><i class="icon-calendar"></i> 21 Aug 2017 - 27 Sept 2018</p>-->
          <p>{{ item.offer.provider_name }}</p>
        </div>
      </div>
      <div class="cart-item-details-right">
        <div class="cart-item-details-range-wrapper">
          <div class="cart-item-details-range">
            <button
              [ngClass]="{ 'hide-elem': initialItemInCart <= 1 }"
              (click)="changeQtyValue(-1, initialItemInCart)"
            >
              <i class="icon-minus-1"></i>
            </button>
            <input
              type="text"
              class="input-cart-range-qty"
              [(ngModel)]="initialItemInCart"
              value="initialItemInCart"
              (ngModelChange)="changeQtyValue(0, $any($event))"
            />

            <button
              [ngClass]="{ 'hide-elem': initialItemInCart >= qtyValues }"
              (click)="changeQtyValue(1, initialItemInCart)"
            >
              <i class="icon-plus-1"></i>
            </button>
          </div>
        </div>

        <div class="cart-steps-price">
          <div class="cart-step-price-old"></div>
          <div class="cart-step-price-new">
            {{
              item.offer.current_price.price * initialItemInCart
                | currency : item.offer.current_price.currency
            }}
          </div>
        </div>
        <!-- <ng-container *ngIf="initialItemInCart >= qtyValues">
            <div class="inner-notification">
                <p>{{ 'GENERALS.UNAVAILABLE-QTY' | translate }}</p>
                <p>{{ 'GENERALS.MAX-AVAILABLE-QTY' | translate }} {{qtyValues}} {{ 'GENERALS.ITEMS' | translate }}</p>
            </div>
        </ng-container> -->
      </div>
    </div>
  </div>

  <div class="clear"></div>

  <div class="modal-footer-custom">
    <button class="btn gray-bg fl-l" (click)="onNoClick()">
      {{ "GENERALS.CONTINUE-SHOPING" | translate }}
    </button>
    <button class="btn orange-bg fl-r" (click)="navigateToCart()">
      {{ "GENERALS.PROCEED-CHECKOUT" | translate }}
    </button>
    <div class="clear"></div>
  </div>
</div>
