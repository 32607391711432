import { Injectable } from "@angular/core";
import { TaskActions } from "@api/case-tasks/actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap } from "rxjs";
import { CaseActions } from "../actions";
import {
  ModelChangeData,
  ModelType,
} from "@api/case-tasks/models/model-change.data";
import { Update } from "@ngrx/entity";
import { TypedAction } from "@ngrx/store/src/models";

@Injectable()
export class ChangeEffects {
  otherChangesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TaskActions.requestUpdateTaskSuccess,
        CaseActions.requestUpdateCaseSuccess,
        CaseActions.requestCreateServicesSuccess,
        CaseActions.requestUpdateServiceSuccess,
        TaskActions.requestCreateTaskSuccess
      ),
      mergeMap(({ other_changes }) => {
        const actions: Array<TypedAction<string>> = [
          ...this.modelChangesToActions(other_changes),
        ];
        return actions;
      })
    );
  });

  protected modelChangesToActions(other_changes) {
    if (Array.isArray(other_changes) && other_changes.length > 0) {
      const groupedChanges = other_changes.reduce(
        (groups, change: ModelChangeData) => {
          const key = change.modelType;
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push({
            id: +change.modelId,
            changes: change.changes,
          });
          return groups;
        },
        {} as Record<ModelType, Array<Update<any>>>
      );
      return Object.keys(groupedChanges)
        .map((key) => {
          switch (key) {
            case ModelType.CASE:
              return CaseActions.updateCases({
                cases: groupedChanges[key],
              });
            case ModelType.TASK:
              return TaskActions.updateTasks({
                tasks: groupedChanges[key],
              });
            default:
              return null;
          }
        })
        .filter((action) => action);
    }
    return [];
  }

  constructor(private actions$: Actions) {}
}
