import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StatsMapperService } from "@api/stats/services";
import { StatsService } from "@api/stats/services";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [StatsService, StatsMapperService],
})
export class StatsModule {}
