import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@environment/environment";
import { StripeInvoice } from "@models/stripe-invoice";
import { Stripe, StripeError } from "@stripe/stripe-js";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StripeService {
  stripe$: Observable<Stripe> = of(); //from(loadStripe(environment.stripe_public_key));
  private apiUrl = environment.gateway_endpoint;

  constructor(private http: HttpClient, protected route: ActivatedRoute) {}

  getSession(stripeInvoice: StripeInvoice): Observable<string> {
    return this.http
      .post<any>(`${this.apiUrl}/invoice/stripe/pay-invoice`, stripeInvoice)
      .pipe(
        map((model) => {
          return model.result.sessionId;
        })
      );
  }

  redirectToCheckout(
    sessionId: string
  ): Observable<never | { error: StripeError }> {
    return this.stripe$.pipe(
      mergeMap((stripe: Stripe) => stripe.redirectToCheckout({ sessionId }))
    );
  }

  goToCheckout(stripeInvoice: StripeInvoice) {
    return this.getSession(stripeInvoice).pipe(
      mergeMap((sessionId: string) => this.redirectToCheckout(sessionId))
    );
  }

  confirmPayment(stripeSessionId): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/invoice/stripe/confirm-payment`, {
        session_id: stripeSessionId,
      })
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getStripeSessionId() {
    return this.route.snapshot.queryParamMap.get("session_id");
  }
}
