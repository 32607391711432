import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { AuthenticationService } from "@api/authentication";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class OrdersService {
  private ordersUrl = "billing/orders";
  private orderHistory = "history/order";

  constructor(private http: HttpClient, private auth: AuthenticationService) {}
  // GET Orders
  getOrdersList(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.ordersUrl, {
      params: params,
    });
  }

  getOrderById(params, id): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}${this.ordersUrl}/${id}`,
      params
    );
  }

  getOrderByIdentityNumber(params, id): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}${this.ordersUrl}/get-order-by-identity-number/${id}`,
      params
    );
  }

  /**
   * Assign expats
   */
  assignOrderExpat(orderID, data): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.ordersUrl}/${orderID}/assignments`,
        { ...data, order_id: orderID },
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Set arriving  date on order for expats
   */
  arrivingDateExpat(orderID, offerID, expatID, date): Observable<any> {
    const dateTimezone = new Date(date);
    return this.http
      .patch<any>(
        `${environment.gateway_endpoint}${this.ordersUrl}/${orderID}/details/${offerID}/expats/${expatID}`,
        { arriving_on: date },
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateOrderDetails(orderId, orderItemID, params): Observable<any> {
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}${this.ordersUrl}/${orderId}/details/${orderItemID}`,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  deleteAssignedExpat(orderID, offerID, expatID): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          this.ordersUrl +
          "/" +
          orderID +
          "/details/" +
          offerID +
          "/expats/" +
          expatID
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/status-transitions",
      this.auth.setOptions()
    );
  }

  // Update Status Bulk
  updateStatusBulk(status_id, order_ids, reason = ""): Observable<any> {
    let body: any = { status_id: status_id, order_ids: order_ids };
    if (reason) {
      body = {
        status_id: status_id,
        order_ids: order_ids,
        declined_reason: reason,
      };
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + this.ordersUrl,
      body,
      httpOptions
    );
  }

  // GET Orders history
  getOrderHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.orderHistory + "/" + id,
      {
        params: params,
      }
    );
  }

  /**
   * Export filtered orders to CSV
   * @param params
   */
  exportOrders(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/export/orders/",
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  /**
   * List quotes for an order
   * @param orderId
   */
  listQuotes(orderId): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + `/${orderId}/quotes`
    );
  }
}
