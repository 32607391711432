export interface TeamData {
  id: number;
  label: string;
  description?: string;
  total_members?: number;
  roles: TeamRoleData[];
}

export interface TeamRoleData {
  name: string;
  label: string;
}

export interface TeamMemberData {
  team_id: number;
  user_id: number;
  name: string;

  primary_image_path: string;
}
