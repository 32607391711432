import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { DocumentService } from "./services/documents.service";
import { DocumentMapperService } from "./services/document-mapper.service";
import { FileService } from "./services/file.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [DocumentService, DocumentMapperService, FileService, DatePipe],
})
export class DocumentsModule {}
