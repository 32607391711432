import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";

@Injectable()
export class CrmService {
  constructor(private http: HttpClient) {}
  // GET Orders
  goToOrder(marketplaceId, email, MarketplaceCompanyId): Observable<any> {
    return this.http.post<any>(environment.gateway_endpoint + "crm/getUrl", {
      marketplaceId: marketplaceId,
      entity: "Order",
      email: email,
      MarketplaceCompanyId: MarketplaceCompanyId,
    });
  }
}
