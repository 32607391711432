import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";

export class AssignmentMenu {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/assignments/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: "/assignments/" + _id + "/benefit-packages",
        filters: filters,
        label: "Benefit Packages",
        page: "benefit-packages",
        permission: {
          action: PermissionActionEnum.VIEW,
          resource: PermissionResourceEnum.BENEFIT,
          secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
        },
      },
      {
        link: "/assignments/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "history",
      },
    ];
  }
}
