import { HttpClient } from "@angular/common/http";
import { environment } from "@environment/environment";
import { Observable, map } from "rxjs";
import { Integration, SixtyBooking } from "../models";
import { Injectable } from "@angular/core";

@Injectable()
export class SixtyService {
  private baseUrl: string = `${environment.gateway_endpoint}accommodation/integrations/sixty`;

  constructor(private http: HttpClient) {}

  public bookings(): Observable<SixtyBooking[]> {
    return this.http
      .get<{
        success: boolean;
        result: { total: number; items: SixtyBooking[] };
      }>(`${this.baseUrl}/bookings`)
      .pipe(map(({ result }) => result.items));
  }

  public changeStatusForExpats(status: boolean): Observable<Integration> {
    return this.http
      .put<{ result: Integration }>(`${this.baseUrl}`, {
        self_serve: status,
        enabled: true,
      })
      .pipe(map(({ result }) => result));
  }
}
