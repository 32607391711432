import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import {
  CitizenshipUpdateModel,
  CitizenshipViewModel,
  CitizenshipsViewModel,
} from "@models/citizenship";
import { CreateExpatData, ExpatData, UpdateExpatData } from "@models/expat";
import { ExpatMaritalStatusViewModel } from "@models/expat-marital-status";
import { Observable, Subject } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { DictionaryModel } from "../modules/shared/models/dictionary.model";

const expatsUrl = "entities/expats";

const newExpatsUrl = "entities/clients";

const dependentRelationshipUrl = "entities/directories/dependent/relationships";

@Injectable()
export class ExpatsService {
  public clientCanChangeExpatStatus = false;
  public expatUpdated: Subject<void> = new Subject();

  constructor(private http: BackendHttpClient) {}

  public getExpatsList(clientId: number, params = {}): Observable<any> {
    const parseParams = (data) => {
      let params: HttpParams = new HttpParams();
      for (const key of Object.keys(data)) {
        if (data[key]) {
          if (data[key] instanceof Array) {
            params = params.append(key, data[key].join(";"));
          } else {
            params = params.append(key.toString(), data[key]);
          }
        }
      }
      return params;
    };
    return this.http.get<any>(newExpatsUrl + `/${clientId}/expats`, {
      params: parseParams(params),
    });
  }

  public getExpatById(id): Observable<ExpatData> {
    return this.http
      .get<DefaultResponseData<ExpatData>>(expatsUrl + "/" + id)
      .pipe(map((res) => res.result));
  }

  public getStatusTransitions(): Observable<any> {
    return this.http.get("entities/expats/status-transition");
  }

  public createExpat(params: CreateExpatData): Observable<ExpatData> {
    return this.http.post<ExpatData>(expatsUrl, params).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // When client edits expat details
  public updateExpat(params: UpdateExpatData): Observable<any> {
    return this.http.put<any>(expatsUrl + "/" + params.id, params).pipe(
      map((model) => {
        return model;
      }),
      finalize(() => this.expatUpdated.next())
    );
  }
  // Delete Expat
  public deleteExpat(data: {
    expatId: number;
    reason: string;
  }): Observable<any> {
    return this.http
      .delete<any>(expatsUrl + "/" + data.expatId, {
        body: {
          reason: data.reason,
        },
      })
      .pipe();
  }

  public updateStatusBulk(status_id, expats_ids, reason = ""): Observable<any> {
    return this.http.patch<any>("entities/expats/updateStatus", {
      status_id: status_id,
      ids: expats_ids,
      declined_reason: reason,
    });
  }

  // GET Expat history
  public getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>("history/expat/" + id, { params: params });
  }

  public getMaritalStatus(
    params = {}
  ): Observable<ExpatMaritalStatusViewModel> {
    return this.http.get<ExpatMaritalStatusViewModel>(
      expatsUrl + "/get-marital-statuses",
      {
        params: params,
      }
    );
  }

  public getEducationForms(params = {}) {
    return this.http.get<any>(expatsUrl + "/get-education-forms", {
      params: params,
    });
  }

  public getCitizenships(
    expatId: number,
    params = {}
  ): Observable<CitizenshipsViewModel> {
    return this.http.get<CitizenshipsViewModel>(
      expatsUrl + `/${expatId}/citizenships`,
      {
        params: params,
      }
    );
  }

  public getCitizenshipById(
    expatId: number,
    citizenshipId: number,
    params = {}
  ): Observable<CitizenshipViewModel> {
    return this.http.get<CitizenshipViewModel>(
      expatsUrl + `/${expatId}/citizenships/${citizenshipId}`,
      {
        params: params,
      }
    );
  }

  public updateCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<CitizenshipViewModel> {
    return this.http
      .put<CitizenshipViewModel>(
        expatsUrl + `/${expatId}/citizenships/${citizenshipId}`,
        citizenshipUpdateModel
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updatePrimaryCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel = {}
  ): Observable<CitizenshipViewModel> {
    return this.http
      .put<CitizenshipViewModel>(
        expatsUrl + `/${expatId}/citizenships/${citizenshipId}/primary`,
        citizenshipUpdateModel
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public createCitizenship(
    expatId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<CitizenshipViewModel> {
    return this.http
      .post<CitizenshipViewModel>(
        expatsUrl + `/${expatId}/citizenships`,
        citizenshipUpdateModel
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public deleteCitizenship(
    expatId: number,
    citizenshipId: number
  ): Observable<CitizenshipViewModel> {
    return this.http
      .delete<CitizenshipViewModel>(
        expatsUrl + `/${expatId}/citizenships/${citizenshipId}`
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public getExpatContacts(
    clientId: number,
    expatId: number,
    params = {}
  ): Observable<any> {
    return this.http
      .get<any>(`entities/clients/${clientId}/expats/${expatId}/contacts`, {
        params: params,
      })
      .pipe(map((_) => _.result.items));
  }

  public createExpatContact(
    clientId: number,
    expatId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .post<any>(
        `entities/clients/${clientId}/expats/${expatId}/contacts`,
        expatContact
      )
      .pipe(map((_) => _.result));
  }

  public updateExpatContact(
    clientId: number,
    expatId: number,
    contactId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .put<any>(
        `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`,
        expatContact
      )
      .pipe(map((_) => _.result));
  }

  public deleteExpatContact(
    clientId: number,
    expatId: number,
    contactId: number
  ): Observable<any> {
    return this.http
      .delete<any>(
        `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`
      )
      .pipe(map((_) => _.result));
  }

  public getExpatDependents(expatId: number, params = {}): Observable<any> {
    return this.http
      .get<any>(`entities/expats/${expatId}/dependents`, {
        params: params,
      })
      .pipe(map((_) => _.result.dependents));
  }

  public deleteExpatDependents(
    expatId: number,
    dependentId: number
  ): Observable<any> {
    return this.http
      .delete<any>(`entities/expats/${expatId}/dependents/${dependentId}`)
      .pipe(map((_) => _.result));
  }

  public createExpatDependents(params, expatId: number): Observable<any> {
    return this.http
      .post<any>(`entities/expats/${expatId}/dependents`, params)
      .pipe(map((res) => res.result.dependent));
  }
  public updateExpatDependents(
    params,
    expatId: number,
    dependentId: number
  ): Observable<any> {
    return this.http
      .put<any>(`entities/expats/${expatId}/dependents/${dependentId}`, params)
      .pipe(map((res) => res.result.dependent));
  }

  public getExpatDependentRelationships(
    params = {}
  ): Observable<DictionaryModel[]> {
    return this.http
      .get<any>(dependentRelationshipUrl, {
        params: params,
      })
      .pipe(map((_) => _.result));
  }

  public export(params = {}, clientId) {
    return this.http.get("entities/clients/" + clientId + "/expats/export", {
      responseType: "blob",
      params: params,
    });
  }

  public inviteExpat(id) {
    return this.http.post<any>("entities/expats/invite", {
      expat_ids: id,
    });
  }
}
