import { Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { CurrencyData } from "../data";

@Injectable({ providedIn: "root" })
export class CurrencyService {
  private currencies$: Observable<CurrencyData[]>;

  constructor(private http: BackendHttpClient) {}

  public currencies() {
    if (!this.currencies$) {
      this.currencies$ = this.fetchCurrencies().pipe(shareReplay(1));
    }
    return this.currencies$;
  }

  public fetchCurrencies(
    filters: Record<string, any> = {}
  ): Observable<CurrencyData[]> {
    return this.http
      .get<DefaultResponseData<CurrencyData[]>>("intl/currencies", {
        params: filters,
      })
      .pipe(
        map((response: DefaultResponseData<CurrencyData[]>) => response.result)
      );
  }
}
