import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssignmentMapperService } from "@api/assignment/services/assignment-mapper.service";
import { AssignmentService } from "@api/assignment/services/assignment.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AssignmentService, AssignmentMapperService],
})
export class AssignmentsModule {}
