import { CaseSubject } from "./case.model";

export interface CreateCaseData {
  provider_id: number;
  provider_name: string;
  assignment_id: number;
  order_id: number;
  order_number: string;
  client_id: number;
  client_name: string;
  expat_id: string;
  expat_name: string;
  services: CreateCaseService[];
  subject: CaseSubject;
}

export interface CreateCaseService {
  service_id: number;
  service_name: string;
  enabled: boolean;
}
