import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { RFQData, RFQStatusID } from "@api/rfq";

export class RfqHelper {
  constructor(private translate: TranslateService) {}

  timeLeft(rfq: RFQData) {
    const _rfq: Partial<RFQData> = { ...rfq };
    let endDate = moment(_rfq.end_date);
    const now = moment.now();

    // if the RFQ is draft or canceled, time left is N/A
    if (
      _rfq.status === RFQStatusID.DRAFT ||
      _rfq.status === RFQStatusID.CANCELED
    ) {
      return "N/A";
    }

    // if the RFQ is published but not open, show the time remaining until it opens
    if (_rfq.status === RFQStatusID.PUBLISHED) {
      endDate = moment(_rfq.start_date);
    }

    // if the RFQ is closed, but it's still within the offer acceptance period, we'll show time left until then
    // only if the user hasn't yet placed an order for it
    if (_rfq.status === RFQStatusID.CLOSED) {
      endDate.add(14, "days");
    }

    // if the submission period has closed, RFQ must be listed as Expired
    if (endDate.isBefore(now)) {
      return this.translate.instant("RFQ.EXPIRED");
    }

    const timeLeft = moment.duration(endDate.diff(now));

    if (endDate.diff(now, "minutes") < 60) {
      return this.translate.instant("GENERALS.LESS-THAN-AN-HOUR");
    }

    let label = "";

    if (timeLeft.months()) {
      label =
        label +
        timeLeft.months() +
        " " +
        this.translate.instant("GENERALS.MONTHS") +
        " ";
    }

    if (timeLeft.days() || timeLeft.months()) {
      label =
        label + timeLeft.days() + " " + this.translate.instant("GENERALS.DAYS");
    }

    if (timeLeft.hours()) {
      label =
        label +
        " " +
        timeLeft.hours() +
        " " +
        this.translate.instant("GENERALS.HOURS");
    }

    return label;
  }
}
