import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AltoVitaQuoteRequest, Integration } from "../models";
import { environment } from "@environment/environment";
import { Observable, map } from "rxjs";

@Injectable()
export class AltoVitaService {
  private baseUrl: string = `${environment.gateway_endpoint}accommodation/integrations/alto-vita`;

  constructor(private http: HttpClient) {}

  public requestQuote(
    data: AltoVitaQuoteRequest
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.baseUrl}/quotes/requests`,
      data
    );
  }

  public changeStatusForExpats(status: boolean): Observable<Integration> {
    return this.http
      .put<{ result: Integration }>(`${this.baseUrl}`, {
        self_serve: status,
        enabled: true,
      })
      .pipe(map(({ result }) => result));
  }
}
