import { DOCUMENT } from "@angular/common";
import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ViewPortService } from "@services/viewPort.service";

@Directive({
  selector: "[adScroll]",
})
export class AdScrollDirective implements OnInit, AfterViewChecked {
  elemPosition;
  adCreativeHeight;

  constructor(
    private el: ElementRef,
    public renderer: Renderer2,
    private viewPort: ViewPortService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.elemPosition = this.el.nativeElement.offsetTop;
  }

  ngAfterViewChecked(): void {
    const elOffsetWidth = this.el.nativeElement.offsetWidth;
    const banenrId = this.el.nativeElement.id;

    const adCreative = this.el.nativeElement.getElementsByTagName("img");
    const adCreativeWrapper =
      this.el.nativeElement.getElementsByClassName("ad-wrapper");

    if (adCreative.length > 0) {
      this.adCreativeHeight = adCreative[0].getBoundingClientRect().height;
    }

    switch (banenrId) {
      case "ad-top-provider":
        if (adCreative.length > 0) {
          // 1460 recommended banner width
          this.renderer.setStyle(
            adCreative[0],
            "transform",
            "scale(" + elOffsetWidth / 1460 + ")"
          );
          this.renderer.setStyle(
            adCreativeWrapper[0],
            "height",
            this.adCreativeHeight + "px"
          );
        }
        break;
      case "ad-main-category":
        if (adCreative.length > 0) {
          // 1140 recommended banner width
          this.renderer.setStyle(
            adCreative[0],
            "transform",
            "scale(" + elOffsetWidth / 1140 + ")"
          );
          this.renderer.setStyle(
            adCreativeWrapper[0],
            "height",
            this.adCreativeHeight + "px"
          );
        }
        break;
      case "ad-offer-page":
        if (adCreative.length > 0) {
          // 320 recommended banner width
          this.renderer.setStyle(
            adCreative[0],
            "transform",
            "scale(" + elOffsetWidth / 320 + ")"
          );
          this.renderer.setStyle(
            adCreativeWrapper[0],
            "height",
            this.adCreativeHeight + "px"
          );
        }
        break;
      case "ad-offers-category":
        if (adCreative.length > 0) {
          // 1140 recommended banner width
          this.renderer.setStyle(
            adCreative[0],
            "transform",
            "scale(" + elOffsetWidth / 1140 + ")"
          );
          this.renderer.setStyle(
            adCreativeWrapper[0],
            "height",
            this.adCreativeHeight + "px"
          );
        }
        break;
      case "ad-offers-filter":
        if (adCreative.length > 0) {
          // 250 recommended banner width
          this.renderer.setStyle(
            adCreative[0],
            "transform",
            "scale(" + elOffsetWidth / 250 + ")"
          );
          this.renderer.setStyle(
            adCreativeWrapper[0],
            "height",
            this.adCreativeHeight + "px"
          );
        }
        break;
    }

    if (this.isElemVisible(this.el.nativeElement)) {
      this.viewPort.sendData("true", this.el.nativeElement.id);
      //    this.el.nativeElement.setAttribute('data-ad', 'true')
    }
    //  this.viewPort.sendData('false', this.el.nativeElement.id);
    //  this.el.nativeElement.setAttribute('data-ad', 'false')
  }

  isElemVisible(el) {
    const rect = el.getBoundingClientRect();
    const vWidth =
      this.document.defaultView.innerWidth ??
      (this.document.documentElement.clientWidth || 1920);
    const vHeight =
      this.document.defaultView.innerHeight ||
      this.document.documentElement.clientHeight;
    const efp = function (x, y) {
      return this.document.elementFromPoint(x, y);
    };

    // Return false if it's not in the viewport
    if (
      rect.right < 0 ||
      rect.bottom < 0 ||
      rect.left > vWidth ||
      rect.top > vHeight
    ) {
      return false;
    }

    // Return true if any of its four corners are visible
    return (
      el.contains(efp(rect.left, rect.top)) ||
      el.contains(efp(rect.right, rect.top)) ||
      el.contains(efp(rect.right, rect.bottom)) ||
      el.contains(efp(rect.left, rect.bottom))
    );
  }
}
