import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Directive()
@Injectable()
export class CrossNotificationComponent {
  private subject = new BehaviorSubject({});

  @Output() entityStatusChange: EventEmitter<any> = new EventEmitter();

  sendData(data) {
    this.subject.next({ data: data });
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }
}
