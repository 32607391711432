import { Injectable } from "@angular/core";
import { CaseTaskData, TaskTag } from "@api/case-tasks/models";
import { TimelineCaseTask } from "@api/case-tasks";
import * as moment from "moment";

@Injectable()
export class TimelineMapperService {
  mapOne(
    input: CaseTaskData,
    currentMonth?: Date,
    isSubTask?: boolean
  ): TimelineCaseTask {
    const forecastStartDate = input.forecast_start_date;
    const forecastEndDate = input.forecast_end_date;

    const currentM = moment(currentMonth);
    const forecastStartDateM = moment(forecastStartDate);
    const forecastEndDateM = moment(forecastEndDate);

    return {
      ...input,
      startDateDay:
        currentMonth &&
        forecastEndDate &&
        (currentMonth.getMonth() > forecastStartDate.getMonth() ||
          currentMonth.getFullYear() !== forecastStartDate.getFullYear())
          ? 1
          : forecastStartDate.getDate(),

      endDateDay:
        currentMonth &&
        currentMonth.getMonth() === forecastEndDate.getMonth() &&
        currentMonth.getFullYear() === forecastEndDate.getFullYear()
          ? forecastEndDate.getDate()
          : Number(currentM.endOf("month").format("DD")),

      isNextMonthOverlapped:
        currentMonth && currentMonth.getMonth() !== forecastEndDate.getMonth(),

      isPreviousMonthOverlapped:
        currentMonth &&
        (currentMonth.getMonth() > forecastStartDate.getMonth() ||
          currentMonth.getFullYear() !== forecastStartDate.getFullYear()),

      expireIn:
        forecastStartDate && forecastEndDate
          ? Math.ceil(
              (forecastEndDate.getTime() - forecastStartDate.getTime()) /
                (1000 * 3600 * 24)
            )
          : 0,

      active: true,

      activeStatus: this.getTaskPrimaryColor(input),

      isSubTask: isSubTask ? true : null,

      // Sub-tasks can be active through the period of time, not just for the current and end date
      isCurrentMonthSubTaskActive:
        (isSubTask &&
          currentMonth &&
          currentM.isBetween(forecastStartDateM, forecastEndDateM)) ||
        currentM.isSame(forecastStartDateM, "month") ||
        currentM.isSame(forecastEndDateM, "month")
          ? true
          : null,

      subtasks: this.mapMany(
        input.subtasks ? input.subtasks : [],
        currentMonth,
        true
      ),
    };
  }

  mapMany(
    input: CaseTaskData[],
    currentMonth?: Date,
    isSubTask?: boolean
  ): TimelineCaseTask[] {
    return input.map((i) => this.mapOne(i, currentMonth, isSubTask));
  }

  prepare(input: any): CaseTaskData[] {
    return {
      ...input,
    };
  }

  private getTaskPrimaryColor(input: CaseTaskData): string {
    let status = input.status;

    const isStatusFound = input.tags.find(
      (tag: TaskTag) => tag.slug === status
    );
    if (isStatusFound) {
      return status;
    }

    input.tags.forEach((tag: TaskTag) => {
      if (tag.slug !== status) {
        status = tag.slug;
      }
    });

    return status;
  }
}
