import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { ProvidersCommissions } from "@models/providers-commissions";
import { Provider } from "@models/provider";
import { PageData } from "@modules/shared/models/page.model";
import { ClientProviderModel } from "@modules/providers/models/client-provider.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class ProvidersService {
  private readonly providersUrl: string = "entities/clients/";

  constructor(private http: HttpClient) {}

  /**
   * List the client's providers
   * @param clientId
   * @param params
   */
  public list(
    clientId,
    params = {}
  ): Observable<PageData<ClientProviderModel>> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}entities/clients/${clientId}/providers`,
        { params }
      )
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: items,
          } as PageData<ClientProviderModel>;
        })
      );
  }

  // GET Providers
  getProviders(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl,
      { params: params }
    );
  }

  // GET Provider by id
  getProvider(id: number): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl + "/" + id
    );
  }

  // GET Providers
  getProviderCommission(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.providersUrl + `/${id}/commission`
    );
  }

  // Get provider categories
  getProviderCategories(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.providersUrl +
        "/" +
        id +
        "/getCategories"
    );
  }

  /**
   * Get rating criteria
   */
  getRatingCriteria(): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}entities/providers/ratings/criteria`
    );
  }

  /**
   * Rate provider based on criteria
   */
  rateProvider(providerId: number, params): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/ratings`,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Get rating permission for logged user
   */
  getRatingPermissions(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        this.providersUrl +
        `/${id}/can-user-add-review`
    );
  }

  /**
   * Check if user has given a rate for selected provider
   */
  getRatingActivity(providerId: number, params = {}): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}entities/providers/${providerId}/ratings`,
      { params: params }
    );
  }

  createProviderStep1(provider: Provider, clientId: number): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          this.providersUrl +
          clientId +
          "/providers",
        provider,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
  // private providersUrl = 'entities/clinets/';

  // POST Create Provider step 2
  createProviderStep2(
    provider: Provider,
    id: number,
    clientId: number
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          this.providersUrl +
          clientId +
          "/providers/" +
          id,
        provider,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create Provider step 3
  createProviderStep3(
    clientId,
    providerId: number,
    categories: ProvidersCommissions
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/providers/${providerId}/categories`,
        categories,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  setCountryProviderStep3(countries, providerId, clientId): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/clients/${clientId}/providers/${providerId}/countries`,
        countries,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
