import { Injectable } from "@angular/core";
import { MilestoneData } from "@api/milestones";

@Injectable()
export class MilestonesMapperService {
  mapOne(input: any): MilestoneData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): MilestoneData[] {
    return input.map((i) => this.mapOne(i));
  }
  prepare(input: any): MilestoneData[] {
    return {
      ...input,
    };
  }
}
