import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentMapperService } from "@api/documents/services/document-mapper.service";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GeneratePdfData } from "../contracts/generator/generate-pdf.data";

@Injectable({
  providedIn: "root",
})
export class GeneratorService {
  constructor(
    private http: HttpClient,
    private documentMapperService: DocumentMapperService
  ) {}

  generatePdf(input: GeneratePdfData): Observable<{ document: DocumentData }> {
    return this.http
      .post(
        environment.gateway_endpoint +
          `templates/${input.template_id}/generate`,
        input
      )
      .pipe(
        map((response: any) => {
          return {
            document: this.documentMapperService.mapOne(response.document),
          };
        })
      );
  }
}
