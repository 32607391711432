import { Injectable } from "@angular/core";
import { TemplateData, TemplateElementsSections } from "../models";

@Injectable({
  providedIn: "root",
})
export class TemplateMapperService {
  mapOne(input: any): TemplateData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): TemplateData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareTemplateElements(response: any): TemplateElementsSections[] {
    return [
      {
        label: "Formatting (Type/Size)",
        slug: "formatting",
        elements: [
          {
            name: "Arial",
            selected: true,
          },
        ],
        sizes: [12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 40],
      },
      {
        label: "Search",
        slug: "search",
        elements: [
          {
            placeholder: "Search fields",
            type: "input",
            name: "search",
          },
        ],
        search: true,
      },
      ...response.sections,
    ];
  }

  prepareCreate(input: Partial<TemplateData>) {
    return { ...input };
  }

  prepareUpdate(input: Partial<TemplateData>) {
    return { ...input };
  }
}
