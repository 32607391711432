import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AccountActions } from "@api/account/actions";
import { AuthActions } from "@api/authentication";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap } from "rxjs";

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private router: Router) {}

  onAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authorized),
      mergeMap(() => [AccountActions.loadAccount()])
    );
  });

  unauthenticated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.unauthenticated, AuthActions.logout),
      mergeMap(() => [AccountActions.clear()])
    );
  });
}
