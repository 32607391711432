import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import {
  OfficeCreateModel,
  OfficeUpdateModel,
  OfficesViewModel,
  OfficeModel,
} from "@models/office";
import { map } from "rxjs/operators";
import { DefaultResponseData } from "@api/http";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const entitiesURL = "entities/";

@Injectable({
  providedIn: "root",
})
export class OfficesService {
  constructor(private http: HttpClient) {}

  public getOfficesList(
    client_id: number,
    params = {}
  ): Observable<OfficesViewModel> {
    return this.http.get<OfficesViewModel>(
      environment.gateway_endpoint + entitiesURL + `${client_id}/offices`,
      {
        params: params,
      }
    );
  }

  public getOfficeById(
    client_id: number,
    officeId: number
  ): Observable<DefaultResponseData<OfficeModel>> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        entitiesURL +
        `${client_id}/offices/${officeId}`
    );
  }

  public deleteOffice(
    client_id: number,
    officeId: number
  ): Observable<DefaultResponseData<OfficeModel>> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          entitiesURL +
          `${client_id}/offices/${officeId}`
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updateOffice(
    body: OfficeUpdateModel,
    client_id: number,
    officeId: number
  ): Observable<DefaultResponseData<OfficeModel>> {
    return this.http
      .put<DefaultResponseData<OfficeModel>>(
        environment.gateway_endpoint +
          entitiesURL +
          `${client_id}/offices/${officeId}`,
        body,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public createOffice(
    body: OfficeCreateModel,
    client_id: number,
    officeId: number
  ): Observable<DefaultResponseData<OfficeModel>> {
    return this.http
      .post<DefaultResponseData<OfficeModel>>(
        environment.gateway_endpoint +
          entitiesURL +
          `${client_id}/offices/${officeId}`,
        body,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
