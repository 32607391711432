import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ActivityService {
  constructor(private http: BackendHttpClient) {}

  // GET activity
  list(model, modelId, params = {}): Observable<any> {
    return this.http.get<any>(model + "/" + modelId + "/activity", { params });
  }
}
