import { ModelData } from "@api/shared";

export enum ModelType {
  CASE = "case",
  TASK = "task",
}

export interface ModelChangeData extends ModelData {
  modelId: string | number;
  modelType: ModelType;
  original: Record<string, number | string | object>;
  changes: Record<string, number | string | object>;
}
