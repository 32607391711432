export interface MilestoneData {
  id: number;
  name: string;
}

export interface CreateMilestoneData {
  name: string;
}

export interface MilestoneReportFilter {
  page: number;
  expat_ids: number[];
  case_manager_ids: number[];
  service_ids: number[];
  milestone_ids: number[];
  time_frame: string;
  show_end_dates: boolean;
}
