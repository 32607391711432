import { Injectable } from "@angular/core";
import { CreateTeamData } from "../data/create-team.model";
import { UpdateTeamData } from "../data/update-team.model";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { Observable, map } from "rxjs";
import { TeamData } from "../data/team.model";
import { PageData } from "@modules/shared/models/page.model";

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  constructor(private http: BackendHttpClient) {}

  fetchTeams(params: Record<string, string>): Observable<PageData<TeamData>> {
    return this.http
      .get(`teams`, { params })
      .pipe(map((response: any) => response.result));
  }

  fetchTeam(id: string | number): Observable<TeamData> {
    return this.http
      .get(`teams/${id}`)
      .pipe(map((response: any) => response.result?.team));
  }

  updateTeam(data: UpdateTeamData) {
    return this.http
      .put(`teams/${data.id}`, data)
      .pipe(map((response: any) => response.result?.team));
  }

  createTeam(data: CreateTeamData) {
    return this.http
      .post(`teams`, data)
      .pipe(map((response: any) => response.result?.team));
  }

  removeTeam(id: string | number) {
    return this.http
      .delete(`teams/${id}`)
      .pipe(map((response: any) => response.result?.team));
  }
}
