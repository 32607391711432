import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RFQOverview } from "@api/stats/models";
import { OrdersByStatus } from "@api/stats/models/orders-by-status.model";
import { StatsMapperService } from "@api/stats/services/stats-mapper.service";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({ providedIn: "root" })
export class StatsService {
  public rfqOverview$: Observable<RFQOverview>;
  public ordersByStatus$: Observable<OrdersByStatus[]>;

  constructor(
    private http: HttpClient,
    private readonly statsMapper: StatsMapperService,
  ) {}

  getRFQsOverview(reload = false): Observable<RFQOverview> {
    if (reload || !this.rfqOverview$) {
      this.rfqOverview$ = this.http
        .get(environment.gateway_endpoint + "statistics/rfq/overview")
        .pipe(
          map((response: any) => {
            return this.statsMapper.mapRFQOverview(response.result);
          }),
          shareReplay(1),
        );
    }

    return this.rfqOverview$;
  }

  getOrdersByStatus(reload = false): Observable<OrdersByStatus[]> {
    if (reload || !this.ordersByStatus$) {
      this.ordersByStatus$ = this.http
        .get(environment.gateway_endpoint + "statistics/orders/by-status")
        .pipe(
          map((response: any) => {
            return this.statsMapper.mapManyOrdersByStatus(response.result);
          }),
          shareReplay(1),
        );
    }
    return this.ordersByStatus$;
  }
}
