import { Injectable } from "@angular/core";
@Injectable()
export class CompareHelper {
  compare(a, b, isAsc) {
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compare_number(a, b, isAsc) {
    return (parseFloat(a) < parseFloat(b) ? -1 : 1) * (isAsc ? 1 : -1);
  }

  setOrder(sort: any = "") {
    if (!sort) {
      return "";
    }
    let order = sort.active;
    if (!sort.direction) {
      order = "";
    }

    return order;
  }

  setDirection(sort: any = "") {
    if (!sort) {
      return "";
    }

    return sort.direction;
  }
}
