import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ActionTypes, UnsafeAction } from "../app.actions";
import { AppState } from "../app.state";

@Injectable()
export class CartEffects {
  setCart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_CART),
      map((action: UnsafeAction) => {
        return { type: ActionTypes.SET_CART_SUCCESS, payload: action.payload };
      })
    )
  );

  setMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_MESSAGES),
      map((action: UnsafeAction) => {
        return {
          type: ActionTypes.SET_MESSAGES_SUCCESS,
          payload: action.payload,
        };
      })
    )
  );

  setFav$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_FAV),
      map((action: UnsafeAction) => {
        return {
          type: ActionTypes.SET_FAV_SUCCESS,
          payload: action.payload,
        };
      })
    )
  );

  constructor(private actions$: Actions, private store: Store<AppState>) {}
}
