import { Action } from "@ngrx/store";
import { type } from "../_helpers/type";

export const ActionTypes = {
  // CART ACTIONS
  SET_CART: type("[Cart] Set Cart"),
  SET_CART_SUCCESS: type("[Cart] Save Success"),
  SET_CART_FAILED: type("[Cart] Save Failed"),
  SET_MESSAGES: type("[Messages] Set Messages"),
  SET_MESSAGES_SUCCESS: type("[Messages] Save Success"),
  SET_MESSAGES_FAILED: type("[Messages] Save Failed"),
  SET_FAV_SUCCESS: type("[FAV] Save Success"),
  SET_FAV_ERROR: type("[FAV] Save Failed"),
  SET_FAV: type("[FAV] Set Fav"),
};

export interface UnsafeAction extends Action {
  payload?: any;
}

export class SetCartItems implements UnsafeAction {
  type = ActionTypes.SET_CART;
  constructor(public payload: any) {}
}

export class SetMessagesNum implements UnsafeAction {
  type = ActionTypes.SET_MESSAGES;
  constructor(public payload: any) {}
}

export class SetFavoriteNum implements UnsafeAction {
  type = ActionTypes.SET_FAV;
  constructor(public payload: any) {}
}
