import { Injectable } from "@angular/core";
import { RFQOverview } from "@api/stats/models";
import { OrdersByStatus } from "@api/stats/models/orders-by-status.model";

@Injectable()
export class StatsMapperService {
  mapRFQOverview(input: any): RFQOverview {
    return {
      ...input,
    };
  }

  mapManyOrdersByStatus(input: any[]): OrdersByStatus[] {
    return input.map((i) => this.mapOrdersByStatus(i));
  }

  mapOrdersByStatus(input: any): OrdersByStatus {
    return {
      ...input,
    };
  }
}
