import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, distinct, mergeMap } from "rxjs";
import { RFQService } from "@api/rfq";
import { RfqActions } from "@api/rfq/actions";

@Injectable()
export class RfqEffects {
  onSelect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RfqActions.selectRfq),
      distinct((a) => a.rfqId),
      mergeMap(({ rfqId }) => [RfqActions.fetchRfq({ rfqId })])
    );
  });

  onFetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RfqActions.fetchRfq),
      concatMap(({ rfqId }) => this.service.details(rfqId)),
      mergeMap((data) => [RfqActions.fetchRfqSuccess({ item: data })]),
      catchError((error) => [RfqActions.fetchRfqError({ error })])
    );
  });

  constructor(private actions$: Actions, private service: RFQService) {}
}
