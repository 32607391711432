import { Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Subject } from "rxjs";

@Injectable()
export class DateService {
  private subject = new Subject<any>();

  setCalendarLimit(referenceDate) {
    return new NgbDate(
      referenceDate.getFullYear(),
      referenceDate.getMonth() + 1,
      referenceDate.getDate()
    );
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }

  substractDayToDate(date, val) {
    return moment(date).subtract(val, "day").format();
  }
}
