import { Injectable } from "@angular/core";
import { InternalProcessData } from "../models/internal-process.model";

@Injectable()
export class InternalProcessMapperService {
  mapOne(input: any): InternalProcessData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): InternalProcessData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareCreate(input: InternalProcessData) {
    return { ...input };
  }

  prepare(input: any) {
    return { ...input };
  }
}
