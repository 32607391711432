import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { combineLatest, Observable } from "rxjs";
import { AuthSelectors } from "../../authentication";
import { Store } from "@ngrx/store";
import { filter, map, switchMap, take } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authToken$ = combineLatest([
    this.store.select(AuthSelectors.selectToken),
    this.store.select(AuthSelectors.selectLoaded),
  ]).pipe(
    filter(([_, loaded]) => loaded),
    map(([token, _]) => token)
  );

  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token) {
          return next.handle(
            request.clone({
              setHeaders: { Authorization: "Bearer " + token },
            })
          );
        }
        return next.handle(request);
      })
    );
  }
}
