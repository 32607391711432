<div class="wrapper-content">
  <!-- <app-marketplace-header></app-marketplace-header> -->
  <div class="container-fix">
    <div class="wrapper-fix">
      <div class="not-found-wrapper">
        <div class="not-founnd-title">
          {{ "GENERALS.PAGE-NOT-FOUND" | translate }}
        </div>
        <div class="not-founnd-content">
          {{ "GENERALS.SORRY-COULDNT-FIND-PAGE" | translate }}
        </div>
        <div class="not-found-btn">
          <a [routerLink]="['/']" class="btn orange-bg">
            {{ "LEFT-NAVIGATION.MARKETPLACE" | translate }}
          </a>
          <a class="btn orange-bg" [routerLink]="['/orders']">
            {{ "LABEL.GO-TO-PORTAL" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
