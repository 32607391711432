import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@api/authentication";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@services/alert.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private translate: TranslateService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          if (this.router.url.indexOf("login") === -1) {
            this.authenticationService.logout(error.status);
          }
        }

        if (error?.error?.message) {
          this.alertService.errors(error.error.message);
        } else {
          this.translate.get("GENERALS.GENERIC_ERROR").subscribe((data) => {
            this.alertService.error(data);
          });
        }

        return throwError(() => error);
      })
    );
  }
}
