import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { from, Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

export enum StorageKey {
  USER_DATA = "user_data",
  AUTH_TOKEN = "auth_token",
}
@Injectable({
  providedIn: "root",
})
export class StorageService {
  private storage$: Observable<Storage>;

  constructor(private storage: Storage) {
    this.storage$ = from(this.storage.create());
  }

  getData(key: StorageKey | string): Observable<any> {
    return this.storage$.pipe(switchMap((storage) => from(storage.get(key))));
  }

  setData(key: StorageKey | string, data: any): Observable<any> {
    return this.storage$.pipe(
      switchMap((storage) => from(storage.set(key, data))),
      catchError((err) => {
        console.error(err);
        return throwError(() => err);
      })
    );
  }

  remove(key: StorageKey | string): Observable<any> {
    return this.storage$.pipe(
      switchMap((storage) => from(storage.remove(key)))
    );
  }

  clearData(): Observable<any> {
    return this.storage$.pipe(switchMap((storage) => from(storage.clear())));
  }
}
