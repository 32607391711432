import { Country } from "@models/country";
import { AssignmentApprover } from "./assignment-approver.model";
import { AssignmentExtensionData } from "./assignment-extension.model";

export interface LinkedResource<T = string> {
  id: T;
  name: string;
}

export interface AssignmentData {
  id: number;
  client_id: number;
  expat_id: number;
  expat_name: string;
  // HOME
  home_country: string;
  home_country_data: Country;
  home_city?: string;
  home_manager_id: number;
  home_manager_name: string;
  home_manager_email: string;
  home_business_unit?: string;
  home_cost_center?: string;
  // COMPANY
  home_company_id?: string;
  home_company_name?: string;
  home_company?: LinkedResource;

  //OFFICE
  home_office_id?: number;
  home_office_name?: number;
  home_office?: LinkedResource<number>;

  // SEGMENT
  home_segment_id?: string;
  home_segment_name?: string;
  home_segment?: LinkedResource;

  // DEPARTMENT
  home_department_id?: string;
  home_department_name?: string;
  home_department?: LinkedResource;

  host_country: string;
  host_country_data: Country;
  host_manager_id: number;
  host_manager_name: string;
  host_manager_email: string;
  host_city: string;
  host_cost_center: string;
  host_business_unit: string;

  // COMPANY
  host_company_id?: string;
  host_company_name?: string;
  host_company?: LinkedResource;

  //OFFICE
  host_office_id?: number;
  host_office_name?: number;
  host_office?: LinkedResource<number>;

  // SEGMENT
  host_segment_id?: string;
  host_segment_name?: string;
  host_segment?: LinkedResource;

  // DEPARTMENT
  host_department_id?: string;
  host_department_name?: string;
  host_department?: LinkedResource;

  payroll?: string;
  number_adults: number;
  number_children: number;
  type: string;
  status: string;
  start_date: string;
  end_date: string;
  sponsor: string;
  notes: string;
  created_at: string;
  provider_id?: number;
  company_name?: string;
  country_code?: string;
  orders?: string;
  order_value?: string;
  currency_code?: string;
  assignment_approvals?: AssignmentApprover[];
  status_class_name?: string;
  extensions?: AssignmentExtensionData[];
}
