import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TeamMemberService, TeamsService } from "./services";
import { TeamRoleService } from "./services/team-roles.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [TeamsService, TeamMemberService, TeamRoleService],
})
export class TeamsModule {}
