import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router, private translate: TranslateService) {
    // clear alert message on route change
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next(null);
        }
      }
    });
  }

  clearErrorMsg() {
    this.subject.next(null);
  }
  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "success", text: [message] });
  }

  error(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "error", text: [message] });
  }

  stringError(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "string-error", text: message });
  }
  private getErrorMessage(message) {
    if (typeof message === "string") {
      return message;
    }
    if (Array.isArray(message)) {
      return this.getErrorMessage(message[0]);
    }
    if (typeof message === "object") {
      return this.getErrorMessage(message[Object.keys(message)[0]]);
    }
    throw new Error("Message not found!");
  }

  errors(messages, keepAfterNavigationChange = false) {
    const message = [];
    if (typeof messages === "string") {
      this.stringError(messages);
      return;
    }
    if (typeof messages === "object" && !Array.isArray(messages)) {
      messages = Object.values(messages);
    }
    if (!Array.isArray(messages)) {
      this.subject.next({
        type: "error",
        text: [this.translate.instant("GENERALS.GENERIC_ERROR")],
      });
      return;
    }
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    for (let i = 0; i < messages.length; i++) {
      try {
        message.push(this.getErrorMessage(messages[i]));
      } catch (e) {
        /* empty */
      }
    }
    if (!message.length) {
      return;
    }
    this.subject.next({ type: "error", text: message });
  }

  closeErrNotification(resp) {
    this.subject.next(resp);
  }

  isCloseErrNotifObs(): Observable<any> {
    return this.subject.asObservable();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  formatLongDeleteError(error, attr): any[] {
    return this.formatLongDeleteError2(error.error.result, attr);
  }

  formatLongDeleteError2(error, attr): any[] {
    const text = [];
    /**
     * @todo fix this ?
     */
    // $.each(error, function (i, val) {
    //   text.push(val[attr]);
    // });
    return text;
  }
}
