import { Injectable } from "@angular/core";
import { RFQServiceRequestDocumentData } from "@api/rfq";

@Injectable()
export class RfqDocumentMapperService {
  mapOne(input: any): RFQServiceRequestDocumentData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): RFQServiceRequestDocumentData[] {
    return input.map((i) => this.mapOne(i));
  }
  prepare(input: any): RFQServiceRequestDocumentData[] {
    return {
      ...input,
    };
  }
}
