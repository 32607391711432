import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencyService } from "./services";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [CurrencyService],
})
export class IntlModule {}
