import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BackendHttpClient } from "@api/http";
import { AssignmentExtensionData } from "../models/assignment-extension.model";

@Injectable({
  providedIn: "root",
})
export class AssignmentExtensionService {
  private url = "cases/assignments";

  constructor(private http: BackendHttpClient) {}

  public store(
    assignmentId: number,
    data: Partial<AssignmentExtensionData>
  ): Observable<AssignmentExtensionData> {
    return this.http
      .post(`${this.url}/${assignmentId}/extensions`, data)
      .pipe(map((response: any) => response.result));
  }
}
