import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class StaticContentService {
  private subject = new BehaviorSubject<any>(" ");

  constructor(private http: HttpClient) {}

  getStaticContent(id): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/service-categories/" + id
    );
  }

  getBlogArticle(): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/nestlers-blog/"
    );
  }

  getBlogCategory(): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/nestlers-blog/taxonomy-terms/"
    );
  }

  getPrivacyPolicy(): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/privacy-policy/"
    );
  }

  getTermsConditions(): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/terms-conditions/"
    );
  }

  getAboutUs(): Observable<any> {
    return this.http.get(environment.drupal_nestlers_url + "export/about-us");
  }

  getEuInfo(): Observable<any> {
    return this.http.get(environment.drupal_nestlers_url + "export/eu-info");
  }

  getSliderData(): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/carousel-marketplace"
    );
  }

  getExpatTerms(): Observable<any> {
    return this.http.get(
      environment.drupal_nestlers_url + "export/client-terms-conditions"
    );
  }
}
