import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class FinancialReportChartService {
  private costs = [];
  private serviceCategories = [];
  private data = [];
  private orderedCountriesByServices = [];

  seriesOrder = [
    "All services",
    "Relocation",
    "Immigration",
    "Taxation",
    "Moving",
  ];

  private config = {
    view: [900, 400],
    showXAxis: true,
    showYAxis: true,
    showXAxisLabel: true,
    showYAxisLabel: true,
    roundEdges: false,
    barPadding: 8,
    groupPadding: 15,
    customColors: [
      {
        name: "All services",
        value: "#ff521d",
      },
      {
        name: "all",
        value: "#ff521d",
      },
      {
        name: "Relocation",
        value: "#fab011",
      },
      {
        name: "Immigration",
        value: "#89b51a",
      },
      {
        name: "Taxation",
        value: "#618a1b",
      },
      {
        name: "Moving",
        value: "#7c401e",
      },
    ],
    yAxisTicks: [],
    legend: true,
    legendTitle: "",
  };

  public getData(res, startDate, endDate, serviceFilter = null) {
    this.mapCosts(res, serviceFilter);
    this.mapServiceCategories(res);
    this.mapData(res, startDate, endDate, serviceFilter);
    this.setPadding(startDate, endDate);
    this.setYAxisTicks();

    return {
      costs: this.costs,
      serviceCategories: this.serviceCategories,
      data: this.data,
      config: this.config,
      hasValues: !!this.getMaxBarValue(),
    };
  }

  private mapCosts(res, serviceFilter) {
    this.costs = [];

    for (const [key, item] of Object.entries<any>(res.result.items)) {
      const month = moment(key.split("-")[1], "MM").format("MMMM");

      let value: number = 0;
      item.forEach((service) => {
        if (service.name === serviceFilter) {
          value = service.value;
        }
      });
      this.costs.push({
        value: value,
        month,
      });
    }
  }

  private mapServiceCategories(res) {
    this.serviceCategories = [
      {
        name: "All services",
        type: "all",
      },
    ];

    for (const [key, item] of Object.entries(res.result.service_categories)) {
      this.serviceCategories.push({
        name: item["name"],
        type: item["name"].toLowerCase(),
      });
    }

    this.orderServiceCategories();
  }

  private orderServiceCategories() {
    const newServiceCategories = [];

    this.seriesOrder.forEach((item) => {
      const service = this.serviceCategories.find(
        (service) => service.name === item
      );

      if (!service) {
        return;
      }

      newServiceCategories.push(service);
    });

    this.serviceCategories = newServiceCategories;
  }

  private mapData(res, startDate, endDate, serviceFilter = null) {
    this.data = [];

    for (const [key, item] of Object.entries<any>(res.result.items)) {
      const yearMonth: string = key;
      const name: string = moment(key.split("-")[1], "MM").format("MMM");

      let series;
      if (serviceFilter !== "all") {
        series = item.map((serie) => {
          if (serviceFilter === serie.name) {
            return serie;
          }

          return {
            name: serie.name,
            value: 0,
          };
        });
      } else {
        series = item;
      }

      const month = {
        yearMonth,
        name,
        series: series,
      };

      this.data = [...this.data, month];
    }

    this.fillEmptyMonths(startDate, endDate);
  }

  private fillEmptyMonths(startDate, endDate) {
    const newData = [];

    let cloneStartDate = startDate.clone();

    while (
      endDate.isAfter(cloneStartDate, "day") ||
      endDate.isSame(cloneStartDate, "day")
    ) {
      const yearMonth = `${cloneStartDate.year()}-${cloneStartDate.format(
        "MM"
      )}`;

      const month = this.data.find((item) => item.yearMonth === yearMonth);

      if (!month) {
        newData.push({
          yearMonth,
          name: cloneStartDate.format("MMM"),
          series: [
            {
              name: "All services",
              value: 0,
            },
            {
              name: "Relocation",
              value: 0,
            },
            {
              name: "Immigration",
              value: 0,
            },
            {
              name: "Taxation",
              value: 0,
            },
            {
              name: "Moving",
              value: 0,
            },
          ],
        });
      } else {
        newData.push(month);
      }

      cloneStartDate = cloneStartDate.add(1, "month");
    }

    this.data = newData;
  }

  private orderSeries(index) {
    const newSeries = [];

    this.seriesOrder.forEach((item) => {
      const series = this.data[index].series.find(
        (series) => series.name === item
      );

      if (!series) {
        newSeries.push({
          name: item,
          value: 0,
        });

        return;
      }

      newSeries.push(series);
    });

    this.data[index].series = newSeries;
  }

  private setYAxisTicks() {
    const max: number = this.getMaxBarValue();

    const section = max / 3;

    this.config = {
      ...this.config,
      yAxisTicks: this.orderedCountriesByServices,
    };
  }

  setPadding(startDate, endDate) {
    if (endDate.diff(startDate, "months") + 1 >= 9) {
      this.config = {
        ...this.config,
        barPadding: 2,
        groupPadding: 5,
      };
    }
  }

  private getMaxBarValue() {
    let max = 0;

    this.data.forEach((item) => {
      item.series.forEach((series) => {
        if (series.value > max) {
          max = series.value;
        }
      });
    });

    return max;
  }

  private getCountriesOrderedByServices(items: any): void {
    items = this.mapObjectToArray(items.result.items)
      .map((monthService) => monthService.service.services)
      .flatMap((array) => array);

    let countries = [...new Set(items.map((item) => item.country_code))];
    countries = countries.map((item) => {
      return { country_code: item, costs: 0 };
    });

    countries = countries.map(
      (country: { country_code: string; costs: number }) => {
        items.forEach((service) => {
          if (service.country_code === country.country_code) {
            country.costs += service.cost;
          }
        });

        return country;
      }
    );

    this.orderedCountriesByServices = countries
      .sort((a: any, b: any) => {
        return a.costs - b.costs;
      })
      .reverse()
      .slice(0, 5);
  }

  private mapObjectToArray(object): any {
    const array = [];

    for (const [key, value] of Object.entries(object)) {
      array.push({
        id: key,
        service: value,
      });
    }

    return array;
  }
}
