import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AccountEffects } from "./effects";
import { accountFeatureKey, reducer } from "./reducers";
import { AccountService } from "./services";
import { AccountStoreService } from "./services/account-store.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AccountEffects]),
    StoreModule.forFeature(accountFeatureKey, reducer),
  ],
  providers: [AccountService, AccountStoreService],
})
export class AccountModule {}
