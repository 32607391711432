import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageData } from "@modules/shared/models/page.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "@environment/environment";
import {
  CreateMilestoneData,
  MilestoneData,
  MilestonesMapperService,
} from "@api/milestones";

export interface MilestoneListingFilters {
  per_page: string | number;
  page: string | number;
  search_text?: string;
}

@Injectable()
export class MilestonesService {
  private serviceMilestoneUrl = "offers/offers/milestones/";

  constructor(
    private http: HttpClient,
    private readonly milestonesMapperService: MilestonesMapperService
  ) {}

  public list(
    filters: Partial<MilestoneListingFilters>
  ): Observable<PageData<MilestoneData>> {
    return this.http
      .get<any>(environment.gateway_endpoint + this.serviceMilestoneUrl, {
        params: filters,
      })
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.milestonesMapperService.mapMany(data.items);
          return data;
        })
      );
  }

  public details(id): Observable<MilestoneData> {
    return this.http
      .get<any>(environment.gateway_endpoint + this.serviceMilestoneUrl + id)
      .pipe(
        map((response: any) =>
          this.milestonesMapperService.mapOne(response.result)
        )
      );
  }

  public update(input: Partial<MilestoneData>): Observable<MilestoneData> {
    const data = this.milestonesMapperService.prepare(input);
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.serviceMilestoneUrl + input.id,
        data
      )
      .pipe(
        map((response: any) =>
          this.milestonesMapperService.mapOne(response.result)
        )
      );
  }

  public create(milestone: CreateMilestoneData) {
    return this.http.post<any>(
      environment.gateway_endpoint + this.serviceMilestoneUrl,
      milestone
    );
  }

  public delete(milestoneId: number) {
    return this.http.delete<any>(
      environment.gateway_endpoint + this.serviceMilestoneUrl + milestoneId
    );
  }
}
