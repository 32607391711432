import { HttpEvent } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FileService {
  protected http = inject(BackendHttpClient);

  upload(file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post("files", formData, {
      reportProgress: true,
      observe: "events",
    });
  }
}
