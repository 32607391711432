import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { PageData } from "@modules/shared/models/page.model";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import {
  RFQData,
  RFQEligibleProviderData,
  RfqEligibleProviderMapperService,
  RfqMapperService,
  RFQServiceOfferData,
  RFQServiceRequestDocumentData,
} from "@api/rfq";
import { RfqDocumentMapperService } from "@api/rfq/services/rfq-document-mapper.service";
import { Store } from "@ngrx/store";
import { RfqActions } from "@api/rfq/actions";

export interface RFQListingFilters {
  per_page: string | number;
  page: string | number;
  search_text?: string;
}

@Injectable()
export class RFQService {
  private url = "offers/rfq/";

  constructor(
    private http: HttpClient,
    private readonly mapper: RfqMapperService,
    private readonly documentMapper: RfqDocumentMapperService,
    private readonly eligibleProviderMapper: RfqEligibleProviderMapperService,
    private readonly store: Store
  ) {}

  public list(
    filters: Partial<RFQListingFilters>
  ): Observable<PageData<RFQData>> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.url}`, {
        params: filters,
      })
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.mapper.mapMany(data.items);
          return data;
        })
      );
  }

  public details(id: number): Observable<RFQData> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.url}${id}`)
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public transitions(): Observable<any> {
    return this.http
      .get(`${environment.gateway_endpoint}${this.url}transitions`)
      .pipe(map((response: any) => response.result));
  }

  public doStatus(
    id: number,
    status: number | string,
    declined_reason = ""
  ): Observable<RFQData> {
    const data: any = { status, declined_reason };
    return this.http
      .post<any>(`${environment.gateway_endpoint}${this.url}${id}/status`, data)
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public status(
    id: number,
    status: number | string,
    reason = ""
  ): Observable<RFQData> {
    this.store.dispatch(RfqActions.requestUpdateStatus({ status: status }));
    return this.doStatus(id, status, reason).pipe(
      tap({
        next: (item) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public doDraft(data: Partial<RFQData>): Observable<RFQData> {
    return this.http
      .post<any>(`${environment.gateway_endpoint}${this.url}draft`, data)
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public draft(data: Partial<RFQData>): Observable<RFQData> {
    this.store.dispatch(RfqActions.requestUpdateRfq({ item: data }));
    return this.doDraft(data).pipe(
      tap({
        next: (item) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public doCreate(data: Partial<RFQData>): Observable<RFQData> {
    return this.http
      .post<any>(`${environment.gateway_endpoint}${this.url}`, data)
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public create(data: Partial<RFQData>): Observable<RFQData> {
    this.store.dispatch(RfqActions.requestUpdateRfq({ item: data }));
    return this.doCreate(data).pipe(
      tap({
        next: (item) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public doUpdate(id: number, data: Partial<RFQData>): Observable<RFQData> {
    return this.http
      .put<any>(`${environment.gateway_endpoint}${this.url}${id}`, data)
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public update(id: number, data: Partial<RFQData>): Observable<RFQData> {
    this.store.dispatch(RfqActions.requestUpdateRfq({ item: data }));
    return this.doUpdate(id, data).pipe(
      tap({
        next: (item) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public upload(
    id: number,
    serviceRequestId: number,
    file: File
  ): Observable<RFQServiceRequestDocumentData> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/documents`,
        formData
      )
      .pipe(
        map((response: any) => this.documentMapper.mapOne(response.result))
      );
  }

  public documents(
    id: number,
    serviceRequestId: number
  ): Observable<PageData<RFQServiceRequestDocumentData>> {
    return this.http
      .get(
        `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/documents`
      )
      .pipe(
        map((response: any) => {
          const data = response.result;
          data.items = this.documentMapper.mapMany(data.items);
          return data;
        })
      );
  }

  public deleteDocument(
    id: number,
    serviceRequestId: number,
    documentId: number
  ): Observable<any> {
    return this.http.delete(
      `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/documents/${documentId}`
    );
  }

  public offerDocuments(
    id: number,
    serviceRequestId: number,
    serviceOfferId: number
  ): Observable<PageData<RFQServiceRequestDocumentData>> {
    return this.http
      .get(
        `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/offers/${serviceOfferId}/documents`
      )
      .pipe(
        map((response: any) => {
          const data = response.result;
          data.items = this.documentMapper.mapMany(data.items);
          return data;
        })
      );
  }

  public providers(
    filters: any
  ): Observable<PageData<RFQEligibleProviderData>> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.url}providers`, {
        params: filters,
      })
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.eligibleProviderMapper.mapMany(data.items);
          return data;
        })
      );
  }

  public doManageOffer(
    rfqId: number,
    serviceRequestId: number,
    offerId: number,
    data: Partial<RFQServiceOfferData>
  ): Observable<RFQData> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.url}${rfqId}/service-requests/${serviceRequestId}/offers/${offerId}/status`,
        data
      )
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public manageOffer(
    rfqId: number,
    serviceRequestId: number,
    offerId: number,
    data: Partial<RFQServiceOfferData>
  ): Observable<RFQData> {
    this.store.dispatch(RfqActions.requestUpdateRfq({ item: data }));
    return this.doManageOffer(rfqId, serviceRequestId, offerId, data).pipe(
      tap({
        next: (item) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }
}
