import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { ReportAssignment } from "@modules/reports/models/assignment.modal";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  public costs(params): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "reports/costs", {
      params,
    });
  }

  public openCasesForUsers(params = {}): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/open-cases", {
        params,
      })
      .pipe(
        map((res) =>
          res.result.map((item) => ({
            x: item.user.first_name + " " + item.user.last_name,
            y: item.number_of_cases,
            image:
              item.user.primary_image_path ??
              `https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=${item.user.first_name} ${item.user.last_name}`,
          }))
        )
      );
  }

  public openCasesForProviders(params = {}): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/open-cases/providers", {
        params,
      })
      .pipe(
        map((res) =>
          res.result.map((item) => ({
            x: item.company_name,
            y: item.number_of_cases,
            image:
              item.primary_user.user.primary_image_path ??
              `https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=${item.primary_user.user.first_name} ${item.primary_user.user.last_name}`,
          }))
        )
      );
  }

  public assignmentsAboutToEnd(): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/assignment-about-end")
      .pipe(
        map((res) =>
          res.result.items.map((item: ReportAssignment) => ({
            y: item.value,
          }))
        )
      );
  }

  public milestoneFilters(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "reports/milestones/filters",
      {
        params,
      }
    );
  }

  public milestones(data: Record<string, any> = {}): Observable<any> {
    const withoutFilters = { ...data, include: "filters" };
    return this.http
      .post<any>(
        environment.gateway_endpoint + "reports/milestones",
        withoutFilters
      )
      .pipe(map((data) => data?.result))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public export(params = {}): Observable<any> {
    return this.http.post(
      `${environment.gateway_endpoint}reports/milestones/export`,
      params,
      {
        responseType: "blob",
      }
    );
  }

  public customMilestoneList(params = {}): Observable<any> {
    return this.http.get(
      `${environment.gateway_endpoint}reports/milestones/custom`,
      {
        params: params,
      }
    );
  }

  public createCustomMilestone(params = {}): Observable<any> {
    return this.http
      .post(environment.gateway_endpoint + `reports/milestones/custom`, params)
      .pipe(map((response: any) => response.result));
  }

  public updateCustomMilestone(id: number, params = {}): Observable<any> {
    return this.http
      .put(
        environment.gateway_endpoint + `reports/milestones/custom/` + id,
        params
      )
      .pipe(map((response: any) => response.result));
  }

  public customMilestoneView(id, params = {}): Observable<any> {
    return this.http.get(
      `${environment.gateway_endpoint}reports/milestones/custom/` + id,
      {
        params: params,
      }
    );
  }

  public removeCustomMilestone(id: number): Observable<any> {
    return this.http
      .delete(environment.gateway_endpoint + `reports/milestones/custom/` + id)
      .pipe(map((response: any) => response.success));
  }
}
