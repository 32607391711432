import { TeamMemberData } from "./team.model";

export interface CreateTeamMembersData {
  team_id: number;
  users: TeamMemberData[];
  countries: string[];
}
export interface UpdateTeamMemberData {
  team_id: number;
  user_id: number;
  countries: string[];
}

export interface PatchTeamMemberData {
  team_id: number;
  user_id: number;
  default: boolean;
}
