export interface TemplateElement {
  element_id: number;
  id?: string;
  text?: string;
  name?: string;
  page: number;
  value?: string;
  metadata?: object;
  position: {
    x: number;
    y: number;
  };
  styles?: any;
  selected?: boolean;
}
