import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AlertComponent } from "./alert/alert.component";
import { LoaderSpinnerGeneralComponent } from "./loader-spinner-general/loader-spinner-general.component";

@NgModule({
  declarations: [AlertComponent, LoaderSpinnerGeneralComponent],
  exports: [AlertComponent, LoaderSpinnerGeneralComponent],
  imports: [CommonModule, TranslateModule.forChild()],
})
export class AlertModule {}
