import { Injectable } from "@angular/core";
import { DocumentData } from "../models/document.model";
import { CreateDocumentData } from "../models/create-document.model";
import { ShareDocumentData } from "../models/share-document.model";

@Injectable()
export class DocumentMapperService {
  mapOne(input: any): DocumentData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): DocumentData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareCreate(input: Partial<CreateDocumentData>) {
    return { ...input };
  }

  prepareShare(input: ShareDocumentData) {
    return { ...input };
  }

  prepare(input: any) {
    return { ...input };
  }
}
