import { Component } from "@angular/core";
import { environment } from "@environment/environment";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";

export const LOCALE_COOKIE_KEY = "locale_cookie_key";

@Component({
  selector: "app-translate",
  templateUrl: "./translate.component.html",
})
export class TranslateComponent {
  selectedLang;
  constructor(
    public translate: TranslateService,
    private cookieService: CookieService
  ) {}

  setLang() {
    this.selectedLang = this.translate.currentLang;
    const lang = this.cookieService.get(LOCALE_COOKIE_KEY);
    if (lang) {
      this.selectedLang = lang;
      this.translate.use(this.selectedLang);
    } else {
      this.selectedLang = this.translate.currentLang;
    }
  }
  changeLang(e) {
    if (e) {
      this.cookieService.set(
        LOCALE_COOKIE_KEY,
        e,
        100,
        "/",
        environment.marketplace_domain,
        true
      );
    } else {
      this.cookieService.delete(
        LOCALE_COOKIE_KEY,
        "/",
        environment.marketplace_domain
      );
    }
    this.translate.use(e);
  }
}
