import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AccountActions } from "@api/account/actions";
import { AccountSelectors } from "@api/account/selectors";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import {
  CustomPermission,
  CustomPermissionData,
  PermissionData,
} from "@api/users/models/permission.model";
import { CompanyData, UserData } from "@api/users/models/user.model";
import { PageData } from "@modules/shared/models/page.model";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { filterNullish } from "../lib";

@Injectable({ providedIn: "root" })
export class UsersService {
  userUpdates$: Subject<UserData> = new Subject();

  constructor(
    private http: BackendHttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store
  ) {}

  private clientsUrl = "entities/clients";

  // GET current user details
  getCurrentUser(forceReload = false): Observable<UserData> {
    if (forceReload) {
      this.store.dispatch(AccountActions.reloadAccount());
    }

    return this.store.select(AccountSelectors.selectAccountState).pipe(
      filter(({ loaded }) => loaded),
      map(({ user }) => ({ ...user })),
      filterNullish()
    );
  }

  /**
   * Get user details
   * with search, filters and pagination
   */
  getUserDetails(user_id): Observable<any> {
    return this.http.get<any>("users/" + user_id);
  }

  saveClientProfile(client: CompanyData): Observable<any> {
    return this.http
      .post<any>("entities/clients/" + client.id + "/register", client)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * List users
   * with search, filters and pagination
   */
  list(params = {}): Observable<DefaultResponseData<PageData<UserData>>> {
    return this.http.get<any>("users", {
      params: params,
    });
  }

  /**
   * Get user search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get("entities/expats/search-fields");
  }

  /**
   * POST Create User for client
   */
  createUser(clientId: number, user: UserData): Observable<any> {
    return this.http
      .post<any>(`${this.clientsUrl}/${clientId}/users`, user)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * PUT Update User for client
   */
  updateUserAccount(user: UserData): Observable<DefaultResponseData<UserData>> {
    return this.http
      .put<DefaultResponseData<UserData>>("users/" + user.id, user)
      .pipe(tap((response) => this.userUpdates$.next(response.result)));
  }

  /**
   * patch Update current user
   */
  updateCurrentUser(user: UserData): Observable<UserData> {
    return this.http.patch<any>("users/update-current-user", user).pipe(
      map((model) => {
        return model;
      })
    );
  }

  /**
   * patch Update password
   */
  updateCurrentPassword(password, password_confirmation): Observable<any> {
    return this.http
      .patch<any>("users/update-password", {
        password: password,
        password_confirmation: password_confirmation,
      })
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update user Status
  updateStatusBulk(status_id, user_id, reason = null): Observable<any> {
    const body = { status_id: status_id, user_id: user_id };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch<any>("users/status-update", body);
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get("users/statuses-transition");
  }

  getUserRoles(): Observable<any> {
    return this.http.get("client/roles");
  }

  getUserRolePermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(`users/${userId}/permissions/role`);
  }

  getUserDirectPermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(`users/${userId}/permissions/direct`);
  }

  createUserDirectPermissions(
    userId: number,
    permissions: { permissions: string[] }
  ): Observable<string[]> {
    return this.http.post<string[]>(
      `$users/${userId}/permissions/direct`,
      permissions
    );
  }

  deleteUserDirectPermission(
    userId: number | string,
    permissionId: number | string
  ): Observable<any> {
    return this.http.delete(
      `users/${userId}/permissions/direct/${permissionId}`
    );
  }

  deleteEntityPermission(
    userId: number,
    model: number,
    resource: string
  ): Observable<any> {
    return this.http.delete(`users/${userId}/permissions/custom`, {
      params: {
        resource: resource,
        models: model,
      },
    });
  }

  getAvailableDirectPermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `users/${userId}/permissions/direct/available`
    );
  }

  grantCustomPermission(
    userId: string,
    customPermission: CustomPermission
  ): Observable<CustomPermissionData> {
    return this.http.post<CustomPermissionData>(
      `users/${userId}/permissions/custom`,
      customPermission
    );
  }

  getUserPermissions(
    userId: string,
    resourceType: string
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(`users/${userId}/permissions/custom`, {
      params: {
        resource: resourceType,
      },
    });
  }

  getAvailableCustomPermissions(
    resourceType: string
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(`users/permissions/custom`, {
      params: {
        resource: resourceType,
      },
    });
  }

  getUserModels(
    userId: string,
    resourceType: string,
    params = {}
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(`users/${userId}/permissions/models`, {
      params: {
        ...params,
        resource: resourceType,
      },
    });
  }

  getPermissionResources(): Observable<{ result: string[] }> {
    return this.http.get<{ result: string[] }>(`users/permissions/resources`);
  }
}
