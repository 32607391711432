import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http/backend-http-client";
import { PageData } from "@modules/shared/models/page.model";
import { Observable, map } from "rxjs";
import {
  CreateTeamMembersData,
  PatchTeamMemberData,
  UpdateTeamMemberData,
} from "../data/add-team-user.model";
import { TeamData, TeamMemberData } from "../data/team.model";

export interface CreateTeamMembersResponse {
  team: TeamData;
  team_members: TeamMemberData[];
}
export interface DeleteTeamMembersResponse {
  team: TeamData;
  team_members: number[];
}
@Injectable({
  providedIn: "root",
})
export class TeamMemberService {
  constructor(private http: BackendHttpClient) {}

  public getMembers(teamId: number): Observable<PageData<TeamMemberData>> {
    return this.http
      .get(`teams/${teamId}/members`)
      .pipe(map((response: any) => response.result?.team_members));
  }

  addMember(
    data: CreateTeamMembersData
  ): Observable<CreateTeamMembersResponse> {
    return this.http
      .post(`teams/${data.team_id}/members`, data)
      .pipe(map((response: any) => response.result));
  }

  updateMember(data: UpdateTeamMemberData): Observable<TeamMemberData> {
    return this.http
      .put(`teams/${data.team_id}/members/${data.user_id}`, data)
      .pipe(map((response: any) => response.result?.team_member));
  }

  patchMember(data: PatchTeamMemberData): Observable<TeamMemberData> {
    return this.http
      .patch(`teams/${data.team_id}/members/${data.user_id}`, data)
      .pipe(map((response: any) => response.result?.team_member));
  }

  removeMember(
    teamId: number,
    userId: number
  ): Observable<DeleteTeamMembersResponse> {
    return this.http
      .delete(`teams/${teamId}/members/${userId}`)
      .pipe(map((response: any) => response.result));
  }
}
