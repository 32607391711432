import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CartService } from "@services/cart.service";
import { Router } from "@angular/router";
import { CartDetails } from "@models/cart";

@Component({
  selector: "app-add-to-cart-modal",
  templateUrl: "./add-to-cart.component.html",
})
export class AddToCartComponent implements OnInit {
  public item: CartDetails;
  public qtyValues;
  public isLoading = false;
  initialItemInCart: number;

  private request_call;

  constructor(
    public dialogRef: MatDialogRef<AddToCartComponent>,
    private service: CartService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.initialItemInCart = 1;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.service.getCartItems().subscribe((res) => {
      const idx = res.details.findIndex(
        (f) =>
          f.offer_id.toString().toLowerCase() ===
          this.data.id.toString().toLowerCase()
      );
      this.item = res.details[idx];
      this.qtyValues = res.details[idx].offer.current_price.quantity;
      this.initialItemInCart = this.item.quantity;

      this.isLoading = false;
    });
  }

  changeQtyValue(i, qtyI: number) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    if (i === 0 && qtyI > 0) {
      this.initialItemInCart = qtyI;
    } else {
      this.initialItemInCart = Number(qtyI) + Number(i);
    }

    if (
      this.initialItemInCart < 1 ||
      isNaN(this.initialItemInCart) ||
      typeof this.initialItemInCart === "undefined"
    ) {
      this.initialItemInCart = 1;
    }

    this.request_call = this.service
      .updateItemQuantity(this.item.id, { quantity: this.initialItemInCart })
      .subscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  navigateToCart() {
    this.dialogRef.close();
    this.router.navigate(["/cart"]);
  }

  goToOfferDetails(id) {
    this.router.navigate(["/offers/" + id]);
    this.dialogRef.close();
  }
}
