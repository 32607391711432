import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BackendHttpClient } from "@api/http";
import { Dictionary } from "@models/dictionary";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DictionaryMapperService } from "../../shared/services/dictionary-mapper.service";
import { AssignmentMapperService } from "./assignment-mapper.service";
import { AssignmentData } from "@api/assignment";
import { PageData } from "@modules/shared/models/page.model";

@Injectable({
  providedIn: "root",
})
export class AssignmentsService {
  private path = "cases/assignments";
  private statusTransitionsUrl =
    "cases/directories/assignments/status-transitions";
  private subject = new BehaviorSubject<AssignmentData>(null);
  public assignment$ = this.subject.asObservable();

  constructor(
    private http: BackendHttpClient,
    private mapperService: AssignmentMapperService,
    private dictionaryMapper: DictionaryMapperService
  ) {}

  /**
   * List assignments
   * @param params
   */
  public list(params = {}): Observable<PageData<AssignmentData>> {
    const withoutFilters = { ...params };

    const parseParams = (params) => {
      let filters: HttpParams = new HttpParams();
      for (const key of Object.keys(params)) {
        if (params[key]) {
          if (params[key] instanceof Array) {
            params[key].forEach((param) => {
              filters = filters.append(key + "[]", param);
            });
          } else {
            filters = filters.append(key.toString(), params[key]);
          }
        }
      }
      return params;
    };

    return this.http
      .get<any>(`${this.path}`, {
        params: parseParams(withoutFilters),
      })
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: this.mapperService.mapMany(items),
          } as PageData<AssignmentData>;
        })
      );
  }

  /**
   * Get the details of an assignment
   * @param assignmentId
   */
  public show(assignmentId: number): Observable<AssignmentData> {
    return this.http
      .get(`${this.path}/${assignmentId}`)
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  /**
   * Create a new assignment
   * @param assignment
   */
  public store(
    assignment: Partial<AssignmentData>
  ): Observable<AssignmentData> {
    return this.http
      .post(`${this.path}`, assignment)
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  /**
   * Update an existing assignment
   * @param assignment
   */
  public update(
    assignment: Partial<AssignmentData>
  ): Observable<AssignmentData> {
    return this.http
      .post(`${this.path}/${assignment.id}`, assignment)
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  /**
   * Get assignment types
   */
  public types(): Observable<Dictionary[]> {
    return this.http
      .get<any>(`cases/directories/assignments/types`)
      .pipe(map((response) => this.dictionaryMapper.mapMany(response.result)));
  }

  public statusTransitions(params = {}): Observable<any> {
    return this.http
      .get<any>(`${this.statusTransitionsUrl}`, {
        params,
      })
      .pipe(map((data) => data.result));
  }

  public updateStatus(ids, status, notes?, params = {}) {
    const body = {
      status: status,
      assignment_ids: ids,
      ...(notes && { notes }),
    };
    return this.http
      .post<any>(`${this.path}/status`, {
        ...body,
      })
      .pipe(map((data) => data.result));
  }

  public getByUuid(uuid: string): Observable<AssignmentData> {
    return this.http
      .get(`${this.path}/approvals/${uuid}`)
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  public export(params = {}) {
    return this.http.get("cases/assignments/export", {
      responseType: "blob",
      params: params,
    });
  }

  public removeApproval(
    assignmentId: number,
    approvalId: number
  ): Observable<AssignmentData> {
    return this.http
      .delete(`${this.path}/${assignmentId}/approvals/${approvalId}`)
      .pipe(map((response: any) => this.mapperService.mapOne(response.result)));
  }

  public sendForApproval(assignmentId: number): Observable<AssignmentData> {
    return this.http
      .post(`${this.path}/${assignmentId}/approvals/notify`, {})
      .pipe(map((response: any) => this.mapperService.mapOne(response.result)));
  }

  public resendApproval(
    assignmentId: number,
    approvalId: number
  ): Observable<AssignmentData> {
    return this.http
      .post(`${this.path}/${assignmentId}/approvals/${approvalId}`, {})
      .pipe(map((response: any) => this.mapperService.mapOne(response.result)));
  }

  public review(
    uuid: string,
    status: string,
    comment?: string
  ): Observable<AssignmentData> {
    return this.http
      .put<AssignmentData>(`${this.path}/approvals/${uuid}`, {
        status: status,
        comment: comment,
      })
      .pipe(map((response: any) => this.mapperService.mapOne(response.result)));
  }

  public detailsByUuid(uuid): Observable<any> {
    return this.http
      .get<any>(`${this.path}/approval/${uuid}`)
      .pipe(map((response: any) => this.mapperService.mapOne(response.result)));
  }

  public approvalResponseByUuid(
    uuid: number,
    status: string,
    comment?: string
  ): Observable<any> {
    return this.http.put<any>(`${this.path}/approval/${uuid}/response`, {
      status: status,
      comment: comment,
    });
  }
}
