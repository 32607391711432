import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class AdsService {
  private ad = "ads/";

  constructor(private http: HttpClient) {}

  // GET Get Ad by key
  getAdByKey(key, params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + this.ad + key, {
      params: params,
    });
  }

  // Ad Record Click

  adRecordClick(id): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + this.ad + id, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
