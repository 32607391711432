import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  PLATFORM_ID,
} from "@angular/core";

@Directive({
  selector: "img[appLazyLoad]",
})
export class LazyLoadDirective implements AfterViewInit {
  @HostBinding("attr.src") srcAttr = null;
  @Input() src: string;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngAfterViewInit() {
    this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
  }

  private canLazyLoad() {
    return (
      isPlatformBrowser(this.platformId) &&
      window &&
      "IntersectionObserver" in window
    );
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage() {
    this.srcAttr = this.src;
  }
}
