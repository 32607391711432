import { RFQStatusID } from "@api/rfq";

export interface RFQData {
  id: number;
  is_bid: number;
  client_id: number;
  created_by_id: number;
  country?: string;
  city: string;
  number_of_requested_services: number;
  number_of_providers_invited: number;
  request_type: string;
  status_name: string;
  status: RFQStatusID;
  end_date: string;
  end_time: string;
  start_date: string;
  start_time: string;
  delivery_date: string;
  target_price?: number;
  target_currency_id?: number;
  declined_reason?: string;
  update_period?: boolean;
  target_currency_code?: string;
  order_id?: number;
  order_identity_number?: number;
  mix_quality_price?: boolean;
  lowest_price?: boolean;
  providers?: RFQProviderData[];
  categories?: RFQCategoryData[];
  files?: any;
  service_requests?: RFQServiceRequestData[];
  summary: {
    total_amount: number;
    currency_code: string;
  }[];
}

export interface RFQProviderData {
  id: number;
  provider_id: number;
  company_name: string;
  currency: string;
  status: string;
  declined_reason: string;
  edit_flag?: boolean;
}

export interface RFQCategoryData {
  id?: number;
  service_category_id: number;
  service_category_name?: string;
  service_category_path?: string;
  label: string;
}

export interface RFQServiceRequestData {
  id: number;
  service_category_id: number;
  service_description: string;
  quantity: number;
  rfq_type: string;
  additional_info: RFQAdditionalInfoData;
  label?: string;
  documents?: RFQServiceRequestDocumentData[];
  offers?: RFQServiceOfferData[];
}

export interface RFQServiceOfferData {
  company_name: string;
  accepted_by_client: boolean | number;
  currency_code: string;
  id: number;
  offer_id: number;
  offer_name: string;
  price: number;
  primary_image_path: string;
  provider_id: number;
  quantity: number;
}

export interface RFQAdditionalInfoData {
  moving_from?: RFQAdditionalInfoLocationData;
  moving_to?: RFQAdditionalInfoLocationData;
  shipping_method?: any;
  shipment_size?: any;
  storage_required?: any;
  details?: RFQMovingDetail[];
}

export interface RFQAdditionalInfoLocationData {
  country_code: string;
  city: string;
}

export interface RFQMovingDetail {
  label: string;
  value: string;
}

export interface RFQServiceRequestDocumentData {
  created_at: string;
  deleted_at: string;
  entity_id: number;
  entity_type: string;
  entity_type_id: number;
  file: string;
  file_path: string;
  id: number;
  is_primary_image: boolean;
  name: string;
  status: string;
  type: string;
  updated_at: string;
  user_id: number;
  user_name: string;
}

export interface RFQEligibleProviderData {
  id: number;
  name: string;
  slug: string;
  rating: number;
  ratings_count: number;
}
