import { Injectable } from "@angular/core";
import {
  PermissionActionData,
  PermissionData,
} from "@api/users/models/permission.model";

@Injectable()
export class PermissionMapperService {
  mapOne(input: any): PermissionData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): PermissionData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any) {
    return { ...input };
  }

  mapAvailablePermissionsToArray(permissionObj: any) {
    const permissions = [];

    for (const [key, value] of Object.entries(permissionObj)) {
      permissions.push({
        id: key,
        name: value,
      });
    }

    return permissions;
  }

  preparePermissions(input: PermissionActionData[]): string[] {
    return input.map((e) => e.name);
  }

  prepareModels(input: PermissionActionData[]): number[] {
    return input.map((e) => +e.id);
  }
}
