import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { ClientStatuses } from "@models/_statuses/client-statuses";
import { Store } from "@ngrx/store";
import { AccountSelectors } from "@api/account/selectors";
import { catchError, combineLatest, filter, map, of, timeout } from "rxjs";
import { AuthorizationService } from "@api/users/services/authorization.service";

const TIMEOUT_PERIOD = 3000;

@Injectable()
export class ProfileGuard {
  constructor(
    private router: Router,
    private store: Store,
    private authService: AuthorizationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.store.select(AccountSelectors.selectUser),
      this.store.select(AccountSelectors.selectLoaded),
      this.authService.$isExpat(),
    ]).pipe(
      filter(([user, loaded, isExpat]) => loaded === true),
      timeout(TIMEOUT_PERIOD),
      map(([user, loaded, isExpat]) => {
        if (!isExpat) {
          if (user.status_id === ClientStatuses.statuses.incomplete) {
            throw new Error("/create-profile");
          }

          if (
            user.status_id !== ClientStatuses.statuses["terms-not-accepted"] &&
            user.status_id !== ClientStatuses.statuses.active
          ) {
            throw new Error("/clients/company-settings");
          }

          return true;
        }
        if (isExpat) {
          if (
            user.status_id !== ClientStatuses.statuses["terms-not-accepted"] &&
            user.status_id !== ClientStatuses.statuses.active
          ) {
            throw new Error("/account");
          }
          return true;
        }
        return true;
      }),
      catchError((error: string) => of(this.router.createUrlTree([error])))
    );
  }
}
