import { Injectable } from "@angular/core";
import { AssignmentData, CreateAssignmentData } from "@api/assignment";

@Injectable()
export class AssignmentMapperService {
  mapOne(input: any): AssignmentData {
    return {
      ...input,
      end_date: input.end_date ? new Date(input.end_date) : null,
      forecast_end_date: input.forecast_end_date
        ? new Date(input.forecast_end_date)
        : null,
      start_date: input.start_date ? new Date(input.start_date) : null,
      deadline: input.deadline ? new Date(input.deadline) : null,
      status_class_name: input.status.toLowerCase().replace(/ /g, "_"),
    };
  }

  mapMany(input: any[]): AssignmentData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareCreate(input: CreateAssignmentData) {
    return { ...input };
  }
}
