import { inject, Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import {
  DepartmentData,
  NestedDepartmentDataNode,
  StoreDepartmentData,
  UpdateDepartmentData,
} from "../models/department.data";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DepartmentService {
  http = inject(BackendHttpClient);

  getDepartments(params = {}): Observable<DepartmentData[]> {
    return this.http
      .get<DefaultResponseData<DepartmentData[]>>("departments", { params })
      .pipe(map((response) => response.result));
  }

  storeDepartment(data: StoreDepartmentData): Observable<DepartmentData> {
    return this.http
      .post<DefaultResponseData<DepartmentData>>("departments", data)
      .pipe(map((response) => response.result));
  }

  updateDepartment(data: UpdateDepartmentData): Observable<DepartmentData> {
    return this.http
      .put<DefaultResponseData<DepartmentData>>("departments/" + data.id, data)
      .pipe(map((response) => response.result));
  }

  deleteDepartment(id: string) {
    return this.http.delete<void>("departments/" + id);
  }

  buildDepartmentTree(
    departments: DepartmentData[]
  ): NestedDepartmentDataNode[] {
    const buildTree = (parentId) => {
      return departments
        .filter((d) => d.parent_id == parentId)
        .map(
          (d) =>
            ({
              ...d,
              children: buildTree(d.id),
            } as NestedDepartmentDataNode)
        );
    };
    return buildTree(null);
  }
}
