import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { AssignmentMapperService } from "@api/assignment/services/assignment-mapper.service";
import { map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable()
export class AssignmentService {
  private assignmentUrl = "cases/assignments";

  constructor(
    private http: HttpClient,
    private readonly mapperAssignmentService: AssignmentMapperService
  ) {}

  public detailsByUuid(uuid): Observable<any> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}${this.assignmentUrl}/approval/${uuid}`
      )
      .pipe(
        map((response: any) =>
          this.mapperAssignmentService.mapOne(response.result)
        )
      );
  }

  public approvalResponseByUuid(
    uuid: number,
    status: string,
    comment?: string
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.gateway_endpoint}${this.assignmentUrl}/approval/${uuid}/response`,
      { status: status, comment: comment },
      httpOptions
    );
  }
}
