import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "../actions/auth.actions";

export const authFeatureKey = "auth";

export interface State {
  loaded: boolean;
  token?: string;
}

export const initialState: State = {
  loaded: false,
  token: undefined,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.authorized, (state, { token }) => {
    return { ...state, loaded: true, token };
  }),
  on(AuthActions.unauthenticated, (state) => {
    return { ...state, token: undefined };
  }),
  on(AuthActions.logout, (state) => {
    return { ...state, token: undefined };
  }),
  on(AuthActions.loaded, (state) => {
    return { ...state, loaded: true };
  }),
  on(AuthActions.reset, () => {
    return initialState;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
