import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./components/footer/footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    FormsModule,
    TranslateModule,
  ],
})
export class FooterModule {}
