import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { provideClientHydration } from "@angular/platform-browser";
import { FilesHelper } from "@helpers/_models/files.helper";
import { RfqHelper } from "@helpers/_models/rfq.helper";
import { CalendarHelper } from "@helpers/calendar.helper";
import { ActivityService } from "@services/activity.service";
import { DateManagementService } from "@services/date-management.service";
import { CookieService } from "ngx-cookie-service";
import { AppRoutingModule } from "./app-routing.module";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// ########## SERVICES ##########
import { EffectsModule } from "@ngrx/effects";
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";

import { CompareHelper } from "@helpers/compare.helper";
import { AlertService } from "@services/alert.service";
import { CartService } from "@services/cart.service";
import { CrmService } from "@services/crm.service";
import { DictionariesService } from "@services/dictionaries.service";
import { ExpatsService } from "@services/expats.service";
import { MarketService } from "@services/market.service";
import { OrdersService } from "@services/orders.service";
import { ScrollService } from "@services/scroll.service";
import { UsersService } from "@services/users.service";
import { CartEffects } from "@state/effects/cart.effects";
import { ClientGuard } from "./_guards/client.guard";
import { reducers } from "./state";

import { DocumentsService } from "@services/documents.service";
import { SearchService } from "@services/search.service";

import { ApiNotificationService } from "@services/api-notification.service";
import { BreadcrumbService } from "@services/breadcrumb.service";
import { ClientsService } from "@services/client.service";
import { FavoriteService } from "@services/favorite.service";
import { GoogleAnalyticsService } from "@services/google-analytics.service";
import { StaticContentService } from "@services/static-content.service";
import { WindowResizeHelperService } from "@services/window-resize-helper.service";
import { IframeResizeDirective } from "./_directives/iframe-resize";
import { WindowScrollTableBulkDirective } from "./_directives/window-scroll-table-bulk";
import { AccessDeniedPageComponent } from "./components/access-denied/access-denied.component";

import { ProvidersService } from "@services/providers.service";

import { MatDialogModule } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ApiModule } from "@api/api.module";
import { TranslateHttpClient } from "@api/http";
import { AuthInterceptor } from "@api/http/interceptors/auth.interceptor";
import { GetArrayInterceptor } from "@api/http/interceptors/get-array.interceptor";
import { ReportsModule } from "@api/reports/reports.module";
import { TempLoginComponent } from "@api/temp-login/temp-login.component";
import { environment } from "@environment/environment";
import { OrderHelper } from "@helpers/_models/order.helper";
import { DateHelper } from "@helpers/date.helper";
import { Drivers } from "@ionic/storage";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AlertModule } from "@modules/alert/alert.module";
import { DateModule } from "@modules/date/date.module";
import { FooterModule } from "@modules/shared/modules/footer/footer.module";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AdsService } from "@services/ads.service";
import { CartManagementService } from "@services/cart-management.service";
import { CrossNotificationComponent } from "@services/cross-component-notifications.component";
import { DialogService } from "@services/dialog.service";
import { FinancialReportChartService } from "@services/financial-report-chart.service";
import { InternalProcessCategoriesService } from "@services/internal-process-categories.service";
import { LandingPagesService } from "@services/landing-pages.service";
import { RemoveFavoritesService } from "@services/removeFavorites";
import { RFQSService } from "@services/rfqs.service";
import { SvgRegistryService } from "@services/svg-registry.service";
import { ViewPortService } from "@services/viewPort.service";
import { TranslateEffects } from "@state/effects/translate.effects";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";
import { localStorageSync } from "ngrx-store-localstorage";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { ActiveHoverDirective } from "./_directives/active-hover";
import { AdScrollDirective } from "./_directives/adScroll";
import { LazyLoadDirective } from "./_directives/lazy-load";
import { ToggleFiltersDirective } from "./_directives/toggle-filters";
import { StsCalendarHelperDirective } from "./_directives/z-index-helper";
import { ProfileGuard } from "./_guards/profile.guard";
import { CategoriesService } from "./_services";
import { AppEffects } from "./app.effects";
import { NotFoundComponent } from "./components/not-found/not-found.component";

export function HttpLoaderFactory(http: TranslateHttpClient) {
  return new TranslateHttpLoader(http);
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ["tableColumnSettings"], rehydrate: true })(
    reducer
  );
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.marketplace_domain,
  },
  position: "bottom-right",
  theme: "classic",
  palette: {
    popup: {
      background: "#ffffff",
      text: "#000000",
      link: "#f96332",
    },
    button: {
      background: "#f96332",
      text: "#ffffff",
      border: "transparent",
    },
  },
  type: "opt-in",
  content: {
    message:
      "We use cookies to ensure that we give you the best experience on our website. By using our site, you consent to cookies. ",
    link: "Learn more",
    href: "/privacy-policy",
    policy: "Cookie Policy",
  },
};
const initializeKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: {
      realm: "xpath",
      url: environment.realm_url,
      clientId: "marketplace-frontend",
    },
    initOptions: {
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/assets/silent-check-sso.html",
    },
  });

@NgModule({
  declarations: [
    AppComponent,
    // ########## COMPONENTS ##########
    TempLoginComponent,
    AccessDeniedPageComponent,
    NotFoundComponent,
    // ########## DIRECTIVES ##########
    ToggleFiltersDirective,
    ActiveHoverDirective,
    IframeResizeDirective,
    WindowScrollTableBulkDirective,
    StsCalendarHelperDirective,
    AdScrollDirective,
    LazyLoadDirective,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    AlertModule,
    HttpClientModule,
    KeycloakAngularModule,
    ApiModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([CartEffects, TranslateEffects, AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    // ########## LIBRARY ##########
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslateHttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FooterModule,
    NgSelectModule,
    MatDialogModule,
    ReportsModule,
    DateModule,
    IonicStorageModule.forRoot({
      name: "xpath-DB",
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
  ],
  providers: [
    CategoriesService,
    SvgRegistryService,
    ActivityService,
    CookieService,
    UsersService,
    DictionariesService,
    AlertService,
    DocumentsService,
    SearchService,
    ClientGuard,
    ProfileGuard,
    MarketService,
    CartService,
    CompareHelper,
    FilesHelper,
    OrdersService,
    CrmService,
    ExpatsService,
    FinancialReportChartService,
    ScrollService,
    ApiNotificationService,
    GoogleAnalyticsService,
    StaticContentService,
    BreadcrumbService,
    FavoriteService,
    ClientsService,
    ProvidersService,
    WindowResizeHelperService,
    RFQSService,
    LandingPagesService,
    CrossNotificationComponent,
    AdsService,
    ViewPortService,
    RemoveFavoritesService,
    CartManagementService,
    DialogService,
    DateManagementService,
    CalendarHelper,
    TimelineService,
    InternalProcessCategoriesService,
    OrderHelper,
    DateHelper,
    provideClientHydration(),
    { provide: APP_ID, useValue: "serverApp" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetArrayInterceptor,
      multi: true,
    },
    {
      provide: RfqHelper,
      useClass: RfqHelper,
      deps: [TranslateService],
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: "primary",
      },
    },
    { provide: LOCALE_ID, useValue: "en-GB" },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "outline",
        floatLabel: "always",
        subscriptSizing: "fixed",
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha_site_key,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
