import { NgModule } from "@angular/core";
import {
  CaseTaskMapperService,
  TimelineMapperService,
  CaseTasksService,
} from "./services";
import { EffectsModule } from "@ngrx/effects";
import { TaskEffects } from "./effects/task.effects";
import { StoreModule } from "@ngrx/store";
import { fromTask } from "./reducers";

@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([TaskEffects]),
    StoreModule.forFeature(fromTask.tasksFeatureKey, fromTask.reducer),
  ],
  providers: [CaseTasksService, CaseTaskMapperService, TimelineMapperService],
})
export class CaseTasksModule {}
