import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentsModule } from "@api/documents/documents.module";
import {
  GeneratorService,
  TemplateFieldsMapperService,
  TemplateMapperService,
  TemplateService,
} from "./services";

@NgModule({
  declarations: [],
  imports: [CommonModule, DocumentsModule],
  providers: [
    GeneratorService,
    TemplateService,
    TemplateMapperService,
    TemplateFieldsMapperService,
  ],
})
export class TemplatesModule {}
