import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RFQData as RFQ } from "@api/rfq";
import { createReducer, on } from "@ngrx/store";
import { RfqActions } from "@api/rfq/actions";

export const rfqFeatureKey = "rfq";

export interface State extends EntityState<RFQ> {
  // additional entities state properties
  selectedRfqId: null | number;
}
export const adapter: EntityAdapter<RFQ> = createEntityAdapter<RFQ>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedRfqId: null,
});

export const reducer = createReducer(
  initialState,
  on(RfqActions.addRfq, (state, action) => adapter.addOne(action.item, state)),
  on(RfqActions.selectRfq, (state, { rfqId }) => ({
    ...state,
    selectedRfqId: rfqId,
  })),
  on(RfqActions.fetchRfqSuccess, (state, action) =>
    adapter.setOne(action.item, state)
  ),
  on(RfqActions.upsertRfq, (state, action) =>
    adapter.upsertOne(action.item, state)
  ),
  on(RfqActions.addMany, (state, action) =>
    adapter.addMany(action.items, state)
  ),
  on(RfqActions.upsertMany, (state, action) =>
    adapter.upsertMany(action.items, state)
  ),

  on(RfqActions.updateRfq, (state, action) =>
    adapter.updateOne(action.item, state)
  ),
  on(RfqActions.updateMany, (state, action) =>
    adapter.updateMany(action.cases, state)
  ),
  on(RfqActions.deleteRfq, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(RfqActions.deleteMany, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(RfqActions.loadRfq, (state, action) =>
    adapter.setAll(action.items, state)
  ),
  on(RfqActions.clearRfq, (state) => adapter.removeAll(state)),

  // UPDATE RFQ
  on(RfqActions.requestUpdateRfq, (state, action) => ({
    ...state,
    updating: true,
  })),
  on(RfqActions.requestUpdateRfqSuccess, (state, action) =>
    adapter.upsertOne(action.item, {
      ...state,
      updating: false,
    })
  ),
  on(RfqActions.requestUpdateRfqError, (state, action) => ({
    ...state,
    updating: false,
  })),

  // UPDATE RFQ PROVIDER
  on(RfqActions.requestUpdateStatus, (state, action) => ({
    ...state,
    updating: true,
  })),

  // SUBMIT SERVICE OFFER
  on(RfqActions.requestSubmitOffer, (state, action) => ({
    ...state,
    updating: true,
  })),

  // DELETE SERVICE OFFER
  on(RfqActions.requestDeleteOffer, (state, action) => ({
    ...state,
    updating: true,
  }))
);

export const getSelectedRfqId = (state: State) => state.selectedRfqId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectRfqIds = selectIds;

export const selectRfqEntities = selectEntities;

export const selectAllRfq = selectAll;

export const selectRfqTotal = selectTotal;
