import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "@environment/environment";
import { AppState, SetFavoriteNum } from "../state";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class FavoriteService {
  private favorite_url = "offers/favourites";

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  // GET list of favourites
  getList(page = 1): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.favorite_url + "?page=" + page
    );
  }

  // GET list of favourites
  getTotal(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/favourites-total"
    );
  }

  setTotal() {
    this.getTotal().subscribe((res) => {
      this.route.data.subscribe((data) => {
        this.store.dispatch(new SetFavoriteNum(res.result.total_favourites));
      });
    });
  }

  /**
   * add to favourites
   */
  removeFavorite(offer_id): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint + this.favorite_url + "/" + offer_id,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * add to favourites
   */
  addToFavorite(offer_id): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.favorite_url,
        { offer_id: offer_id },
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
