import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { CaseTaskData, CreateTaskData } from "../models";
import { CaseTaskListingFilters } from "../services";
import { ModelChangeData } from "../models/model-change.data";

export const loadCaseTasks = createAction(
  "[Task/API] Load Case Tasks",
  props<{ caseId: number }>()
);

export const loadServiceTasks = createAction(
  "[Task/API] Load Service Tasks",
  props<{ serviceId: number }>()
);

export const loadTasks = createAction(
  "[Task/API] Load Tasks",
  props<{ tasks: CaseTaskData[] }>()
);

export const selectTask = createAction(
  "[Task/API] Select Task",
  props<{ taskId: number }>()
);

export const addTask = createAction(
  "[Task/API] Add Task",
  props<{ task: CaseTaskData }>()
);
export const updateTask = createAction(
  "[Task/API] Update Task",
  props<{ task: Update<CaseTaskData> }>()
);
export const upsertTask = createAction(
  "[Task/API] Upsert Task",
  props<{ task: CaseTaskData }>()
);

export const addTasks = createAction(
  "[Task/API] Add Tasks",
  props<{ tasks: CaseTaskData[] }>()
);
export const updateTasks = createAction(
  "[Task/API] Update Tasks",
  props<{ tasks: Update<CaseTaskData>[] }>()
);
export const upsertTasks = createAction(
  "[Task/API] Upsert Tasks",
  props<{ tasks: CaseTaskData[] }>()
);

export const deleteTask = createAction(
  "[Task/API] Delete Task",
  props<{ id: string }>()
);

export const deleteTasks = createAction(
  "[Task/API] Delete Tasks",
  props<{ ids: string[] }>()
);

export const clearTasks = createAction("[Task/API] Clear Tasks");

// FETCHING SPECIFIC TASK FROM BACKEND
export const fetchTask = createAction(
  "[Task/API] Fetch Task",
  props<{ taskId: number }>()
);

export const fetchTaskSuccess = createAction(
  "[Task/API] Fetch Task Success",
  props<{ task: CaseTaskData }>()
);

export const fetchTaskError = createAction(
  "[Task/API] Fetch Task Error",
  props<{ error: any }>()
);
// FETCHING MULTIPLE TASKS FROM BACKEND
export const fetchTasks = createAction(
  "[Task/API] Fetch Tasks",
  props<{ filters: Partial<CaseTaskListingFilters> }>()
);

export const fetchTasksSuccess = createAction(
  "[Task/API] Fetch Tasks Success",
  props<{ tasks: CaseTaskData[] }>()
);

export const fetchTasksError = createAction(
  "[Task/API] Fetch Tasks Error",
  props<{ error: any }>()
);
// USED TO UPDATE TASK WITH BACKEND
export const requestUpdateTask = createAction(
  "[Task/API] Request Update Task",
  props<{ task: Update<CaseTaskData> }>()
);

export const requestUpdateTaskSuccess = createAction(
  "[Task/API] Request Update Task Success",
  props<{ task: CaseTaskData; other_changes?: Array<ModelChangeData> }>()
);

export const requestUpdateTaskError = createAction(
  "[Task/API] Request Update Task Error",
  props<{ error: any }>()
);
// USED TO CREATE TASK WITH BACKEND
export const requestCreateTask = createAction(
  "[Task/API] Request Create Task",
  props<{ task: CreateTaskData }>()
);

export const requestCreateTaskSuccess = createAction(
  "[Task/API] Request Create Task Success",
  props<{ task: CaseTaskData; other_changes?: Array<ModelChangeData> }>()
);

export const requestCreateTaskError = createAction(
  "[Task/API] Request Create Task Error",
  props<{ error: any }>()
);
