import { Injectable } from "@angular/core";
import { RFQData } from "@api/rfq";

@Injectable()
export class RfqMapperService {
  mapOne(input: any): RFQData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): RFQData[] {
    return input.map((i) => this.mapOne(i));
  }
  prepare(input: any): RFQData[] {
    return {
      ...input,
    };
  }
}
