import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { CaseData, CaseService } from "../models";
import { ModelChangeData } from "@api/case-tasks/models/model-change.data";

export const loadCases = createAction(
  "[Case/API] Load Cases",
  props<{ cases: CaseData[] }>()
);

export const selectCase = createAction(
  "[Case/API] Select Case",
  props<{ caseId: number }>()
);

export const fetchCase = createAction(
  "[Case/API] Fetch Case",
  props<{ caseId: number }>()
);

export const fetchCaseSuccess = createAction(
  "[Case/API] Fetch Case Success",
  props<{ case: CaseData }>()
);

export const fetchCaseError = createAction(
  "[Case/API] Fetch Case Error",
  props<{ error: any }>()
);

export const addCase = createAction(
  "[Case/API] Add Case",
  props<{ case: CaseData }>()
);

export const upsertCase = createAction(
  "[Case/API] Upsert Case",
  props<{ case: CaseData }>()
);

export const addCases = createAction(
  "[Case/API] Add Cases",
  props<{ cases: CaseData[] }>()
);

export const upsertCases = createAction(
  "[Case/API] Upsert Cases",
  props<{ cases: CaseData[] }>()
);

export const updateCase = createAction(
  "[Case/API] Update Case",
  props<{ case: Update<CaseData> }>()
);

export const updateCases = createAction(
  "[Case/API] Update Cases",
  props<{ cases: Update<CaseData>[] }>()
);

export const deleteCase = createAction(
  "[Case/API] Delete Case",
  props<{ id: string }>()
);

export const deleteCases = createAction(
  "[Case/API] Delete Cases",
  props<{ ids: string[] }>()
);

// USED TO UPDATE CASE
export const requestAssignManager = createAction(
  "[Case/API] Request Update Case Manager Case",
  props<{ caseId: number; caseManagerId: string | number }>()
);

export const requestAssignManagerSuccess = createAction(
  "[Case/API] Request Update Case Manager Success",
  props<{
    case: CaseData;
    other_changes?: Array<ModelChangeData>;
  }>()
);

export const requestAssignManagerError = createAction(
  "[Case/API] Request Update Case Manager Error",
  props<{ error: any }>()
);

// USED TO UPDATE CASE
export const requestUpdateCase = createAction(
  "[Case/API] Request Update Case",
  props<{ case: Update<CaseData> }>()
);

export const requestUpdateCaseSuccess = createAction(
  "[Case/API] Request Update Case Success",
  props<{
    case: CaseData;
    other_changes?: Array<ModelChangeData>;
  }>()
);

export const requestUpdateCaseError = createAction(
  "[Case/API] Request Update Case Error",
  props<{ error: any }>()
);

// USED TO CREATE SERVICE
export const requestCreateServices = createAction(
  "[Case/API] Request Create Services",
  props<{ services: CaseService[] }>()
);

export const requestCreateServicesSuccess = createAction(
  "[Case/API] Request Create Services Success",
  props<{
    case: CaseData;
    other_changes?: Array<ModelChangeData>;
  }>()
);

export const requestCreateServicesError = createAction(
  "[Case/API] Request Create Services Error",
  props<{ error: any }>()
);

// USED TO UPDATE SERVICE START DATE
export const requestUpdateService = createAction(
  "[Case/API] Request Update Service",
  props<{ service: Update<CaseService> }>()
);

export const requestUpdateServiceSuccess = createAction(
  "[Case/API] Request Update Service Success",
  props<{
    service: CaseService;
    other_changes?: Array<ModelChangeData>;
  }>()
);

export const requestUpdateServiceError = createAction(
  "[Case/API] Request Update Service Error",
  props<{ error: any }>()
);

export const clearCases = createAction("[Case/API] Clear Cases");
