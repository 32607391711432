import { CreateAssignmentReceiver } from "./assignment-receiver.model";

export interface CreateAssignmentData {
  provider_id?: number;
  client_id: number;
  expat_id: number;
  expat_name: string;
  type: string;
  status: string;
  start_date: string;
  end_date: string;

  // HOME
  home_country: string;
  home_city: string;
  home_manager_id: number;
  home_manager_name: string;
  home_manager_email: string;
  home_company_id: string;
  home_office_id: number;
  home_department_id: string;
  home_segment_id: string;

  // HOST
  host_country: string;
  host_city: string;
  host_manager_id: number;
  host_manager_name: string;
  host_manager_email: string;
  host_company_id: string;
  host_office_id: number;
  host_department_id: string;
  host_segment_id: string;

  created_at: string;
  company_name?: string;
  country_code?: string;
  sponsor: string;
  notes?: string;
  orders?: string;
  order_value?: string;
  currency_code?: string;
  assignment_receiver?: CreateAssignmentReceiver[];
}
