export interface TemplateElementsSections {
  id: number;
  label: string;
  placeholder: string;
  slug: string;
  type: string;
  name?: string;
  elements: TemplateElementsSections[];
  active?: boolean;
}
