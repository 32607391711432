import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { InternalProcessCategoriesModel } from "@api/internal-processes/models/internal-process-categories.model";
import { InternalProcessData } from "@api/internal-processes/models/internal-process.model";

@Injectable()
export class InternalProcessCategoriesService {
  private serviceCategoriesUrl = "offers/service-categories";

  constructor(private http: HttpClient) {}

  // GET Service Categories
  getInternalProcessCategories(
    params: Record<string, any> = {}
  ): Observable<InternalProcessCategoriesModel> {
    params["service_types"] = "internal_process";
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceCategoriesUrl,
      { params: params }
    );
  }

  getInternalProcessById(id): Observable<InternalProcessData> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceCategoriesUrl + "/" + id
    );
  }
}
