import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAccount from "../reducers/account.reducer";

export const selectAccountState = createFeatureSelector<fromAccount.State>(
  fromAccount.accountFeatureKey
);

export const selectUser = createSelector(
  selectAccountState,
  (state: fromAccount.State) => state.user
);
export const selectLoading = createSelector(
  selectAccountState,
  (state: fromAccount.State) => !state.loaded
);
export const selectLoaded = createSelector(
  selectAccountState,
  (state: fromAccount.State) => state.loaded
);
