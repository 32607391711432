export enum PermissionActionEnum {
  VIEW = "view",
  UPDATE = "update",
  CREATE = "create",
  DELETE = "delete",
  DOWNLOAD = "download",
  MANAGE = "manage",
  SOME = "some",
}

export enum PermissionResourceEnum {
  ORGANIZATION = "organization",
  CLIENT = "client",
  PROVIDER = "provider",
  ASSIGNMENT = "assignment",
  EXPAT = "expat",
  CASE = "case",
  TASK = "task",
  DOCUMENT = "document",
  DOCUSIGN = "docusign",
  SERVICE = "service",
  TEMPLATE = "template",
  REPORTS = "reports",
  USER = "user",
  ORDER = "order",
  CATEGORY = "category",
  RFQ = "rfq",
  AD = "ad",
  MESSAGE = "message",
  ADMIN = "admin",
  REMINDER = "reminder",
  BENEFIT = "benefit",
  COST_PROJECTION = "costProjection",
  BENEFIT_LINK = "benefitLink",
  COMPANY_USER = "companyUser",
  MARKETPLACE = "marketplace",
  ACCOMMODATION = "accommodation",
  MILESTONE = "milestone",
  FORM = "form",
  TEAM = "team",
  LEGAL_ENTITY = "legalEntity",
}

export enum PermissionSecondaryResourceEnum {
  DETAILS = "details",
  SEGMENT = "segment",
  DEPARTMENT = "department",
  ASSIGNMENT = "assignment",
  FINANCIAL = "financial",
  ACTIVITY = "activity",
  DEPENDENTS = "dependents",
  PERMISSIONS = "permissions",
  REVIEWS = "reviews",
  INVITES = "invites",
  BANNERS = "banners",
  WORKFLOWS = "workflows",
  OFFICES = "offices",
  CONTACTS = "contacts",
  NOTE = "note",
  MILESTONES = "milestones",
  REMINDERS = "reminders",
  BILLING_DETAILS = "billingDetails",
  LEGAL_ENTITIES = "legalEntities",
}
