import { Inject, Injectable, Optional } from "@angular/core";
import { UserData } from "@api/users/models/user.model";
import { BehaviorSubject, Observable, from, of } from "rxjs";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import { Storage } from "@ionic/storage-angular";

export const USER_DATA_STORAGE_KEY = "user_data";
export const USER_PERMISSIONS_STORAGE_KEY = "permissions_data";

@Injectable({
  providedIn: "root",
})
export class AccountStoreService {
  user$: BehaviorSubject<UserData | null> = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    @Optional()
    @Inject(REQUEST)
    private req: Request<any>
  ) {}

  setUser(user: UserData): void {
    try {
      this.user$.next(user);
      this.saveUser(user);
    } catch (e) {
      return;
    }
  }

  clearStore(): void {
    try {
      this.user$.next(null);
      this.storage.remove(USER_DATA_STORAGE_KEY);
    } catch (e) {
      return;
    }
  }

  saveUser(user: UserData | null): void {
    if (!user) {
      this.clearStore();
      return;
    }
    this.storage.set(USER_DATA_STORAGE_KEY, user);
  }

  loadUser(): Observable<UserData | null> {
    const loadEncodedUser = () => {
      if (this.req) {
        return null;
      }
      return this.storage.get(USER_DATA_STORAGE_KEY);
    };
    try {
      const encodedUser = loadEncodedUser();
      return from(encodedUser);
    } catch (e) {
      return of(null);
    }
  }

  getUserSync(): UserData | null {
    return this.user$.getValue();
  }

  getUser(): Observable<UserData | null> {
    return this.user$.asObservable();
  }
}
