import { Injectable } from "@angular/core";
import { RFQEligibleProviderData } from "@api/rfq";

@Injectable()
export class RfqEligibleProviderMapperService {
  mapOne(input: any): RFQEligibleProviderData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): RFQEligibleProviderData[] {
    return input.map((i) => this.mapOne(i));
  }
  prepare(input: any): RFQEligibleProviderData[] {
    return {
      ...input,
    };
  }
}
