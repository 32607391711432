import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { ErcLandingPage } from "@models/erc-landingpage";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class LandingPagesService {
  constructor(private http: HttpClient) {}

  subscribeErc(ercData: ErcLandingPage): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "lp-subscribe",
        ercData,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
