import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@api/authentication";
import { Cart } from "@models/cart";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AddToCartComponent } from "../modules/marketplace/components/add-to-cart/add-to-cart.component";
import { QuoteModel } from "../modules/quotes/models/quote.model";
import { AppState, SetCartItems } from "../state";
import { AlertService } from "./alert.service";
import { CartService } from "./cart.service";
import { DialogService } from "./dialog.service";

@Injectable()
export class CartManagementService {
  public cart: Cart;

  constructor(
    private service: CartService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private alertService: AlertService,
    private translate: TranslateService,
    private authService: AuthenticationService
  ) {
    authService.currentUser.subscribe((user) => {
      if (user) {
        this.service.getCartItems().subscribe((data) => {
          if (data) {
            this.cart = data;
          }
        });

        this.service.changeCart.subscribe((cart) => {
          if (cart) {
            this.cart = cart;
          }
        });
      }
    });
  }

  addOfferToCart(_offer) {
    let itemId = -1;

    if (this.cart && this.cart.details) {
      itemId = this.cart.details.findIndex(
        (f) => Number(f.offer_id) === Number(_offer.id)
      );
    }

    let quantity = 1;

    if (itemId > -1 && this.cart.details[itemId]) {
      quantity = this.cart.details[itemId].quantity + 1;
    }

    this.service
      .addToCart({
        offer_id: _offer.id,
        // unit_price: _offer.current_price.price,
        unit_price:
          typeof _offer.current_price.price === "number"
            ? _offer.current_price.price
            : _offer.current_price,
        quantity: quantity,
        currency_code: _offer.current_price.currency
          ? _offer.current_price.currency
          : _offer.current_currency,
      })
      .subscribe((data) => {
        this.store.dispatch(new SetCartItems(data.result.details.length));

        const dialogRef = this.dialog.open(AddToCartComponent, {
          data: { id: _offer.id },
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            this.dialogService.cartDialogClosed.emit(_offer);
          },
          (errors) => {
            this.alertService.errors(errors.error.errors);
          }
        );
      });
  }

  addQuoteToCart(quote: QuoteModel) {
    this.service.addToCart(quote).subscribe((data) => {
      this.store.dispatch(new SetCartItems(data.result.details.length));
      this.translate
        .get("SUCCESS.ADDED_TO_CART")
        .subscribe((translation) => this.alertService.success(translation));
    });
  }
}
