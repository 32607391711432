export enum LoadingTypeEnum {
  INFO = "info",
  INFO_ICON = "info_icon",
  BIG_INFO = "big_info",
  PROFILE = "profile",
  TABLE = "table",
  FILTER = "filter",
  CHART = "chart",
  PIE = "pie",
  BUTTONS = "buttons",
  FULL_BUTTON = "full_button",
  SMALL_BUTTON = "small_button",
  WORKFLOW_BUTTON = "workflow-save-button",
  INFO_BUTTONS = "info-buttons",
  TIMELINE = "timeline",
  INPUT = "input",
  CARD = "card",
  BLOCK = "block",
  TAX_ASSIGNMENT = "tax_assignment",
  TAX_ASSIGNMENT_MONTH = "tax_assignment_month",
  CALENDAR = "calendar",
}
