import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AccountActions } from "@api/account/actions";
import { AccountSelectors } from "@api/account/selectors";
import {
  CustomPermission,
  CustomPermissionData,
  PermissionData,
} from "@api/users/models/permission.model";
import { CompanyData, UserData } from "@api/users/models/user.model";
import { environment } from "@environment/environment";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { Observable, timer } from "rxjs";
import { filter, map } from "rxjs/operators";
import { filterNullish } from "../lib";
import { DefaultResponseData } from "@api/http";
import { PageData } from "@modules/shared/models/page.model";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const REFRESH_INTERVAL = 1000; //5 * 60 * 1000; // 5 mins

@Injectable({ providedIn: "root" })
export class UsersService {
  private timer$;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store
  ) {
    this.timer$ = timer(0, REFRESH_INTERVAL);
  }

  private request: Observable<any>;
  private clientsUrl = "entities/clients";

  // GET current user details
  getCurrentUser(forceReload = false): Observable<UserData> {
    if (forceReload) {
      this.store.dispatch(AccountActions.reloadAccount());
    }

    return this.store.select(AccountSelectors.selectAccountState).pipe(
      filter(({ loaded }) => loaded),
      map(({ user }) => ({ ...user })),
      filterNullish()
    );
  }

  /**
   * Get user details
   * with search, filters and pagination
   */
  getUserDetails(user_id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "users/" + user_id
    );
  }

  saveClientProfile(client: CompanyData): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "entities/clients/" +
          client.id +
          "/register",
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * List users
   * with search, filters and pagination
   */
  list(params = {}): Observable<DefaultResponseData<PageData<UserData>>> {
    return this.http.get<any>(environment.gateway_endpoint + "users", {
      params: params,
    });
  }

  /**
   * Get user search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/search-fields"
    );
  }

  /**
   * POST Create User for client
   */
  createUser(clientId: number, user: UserData): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.clientsUrl}/${clientId}/users`,
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * PUT Update User for client
   */
  updateUserAccount(user: UserData): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + "users/" + user.id,
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * patch Update current user
   */
  updateCurrentUser(user: UserData): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + "users/update-current-user",
        user,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * patch Update password
   */
  updateCurrentPassword(
    current_password,
    password,
    password_confirmation
  ): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + "users/update-password",
        {
          current_password: current_password,
          password: password,
          password_confirmation: password_confirmation,
        },
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update user Status
  updateStatusBulk(status_id, user_id, reason = null): Observable<any> {
    const body = { status_id: status_id, user_id: user_id };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + "users/status-update",
      body,
      httpOptions
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "users/statuses-transition"
    );
  }

  getUserRoles(): Observable<any> {
    return this.http.get(environment.gateway_endpoint + "client/roles");
  }

  getUserRolePermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/role`
    );
  }

  getUserDirectPermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct`
    );
  }

  createUserDirectPermissions(
    userId: number,
    permissions: { permissions: string[] }
  ): Observable<string[]> {
    return this.http.post<string[]>(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct`,
      permissions
    );
  }

  deleteUserDirectPermission(
    userId: number | string,
    permissionId: number | string
  ): Observable<any> {
    return this.http.delete(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct/${permissionId}`
    );
  }

  deleteEntityPermission(
    userId: number,
    model: number,
    resource: string
  ): Observable<any> {
    return this.http.delete(
      `${environment.gateway_endpoint}users/${userId}/permissions/custom`,
      {
        params: {
          resource: resource,
          models: model,
        },
      }
    );
  }

  getAvailableDirectPermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct/available`
    );
  }

  grantCustomPermission(
    userId: string,
    customPermission: CustomPermission
  ): Observable<CustomPermissionData> {
    return this.http.post<CustomPermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/custom`,
      customPermission
    );
  }

  getUserPermissions(
    userId: string,
    resourceType: string
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/custom`,
      {
        params: {
          resource: resourceType,
        },
      }
    );
  }

  getAvailableCustomPermissions(
    resourceType: string
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/permissions/custom`,
      {
        params: {
          resource: resourceType,
        },
      }
    );
  }

  getUserModels(
    userId: string,
    resourceType: string,
    params = {}
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/models`,
      {
        params: {
          ...params,
          resource: resourceType,
        },
      }
    );
  }

  getPermissionResources(): Observable<{ result: string[] }> {
    return this.http.get<{ result: string[] }>(
      `${environment.gateway_endpoint}users/permissions/resources`
    );
  }
}
