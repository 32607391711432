import { inject, Injectable } from "@angular/core";
import { BackendHttpClient, DefaultResponseData } from "@api/http";
import {
  SegmentData,
  StoreSegmentData,
  UpdateSegmentData,
} from "../models/segment.data";
import { map, Observable } from "rxjs";
import { PageData } from "@modules/shared/models/page.model";

@Injectable({
  providedIn: "root",
})
export class SegmentService {
  http = inject(BackendHttpClient);

  getSegments(params = {}): Observable<PageData<SegmentData>> {
    return this.http
      .get<DefaultResponseData<PageData<SegmentData>>>("segments", { params })
      .pipe(map((response) => response.result));
  }

  storeSegment(data: StoreSegmentData): Observable<SegmentData> {
    return this.http
      .post<DefaultResponseData<SegmentData>>("segments", data)
      .pipe(map((response) => response.result));
  }

  updateSegment(data: UpdateSegmentData): Observable<SegmentData> {
    return this.http
      .put<DefaultResponseData<SegmentData>>("segments/" + data.id, data)
      .pipe(map((response) => response.result));
  }

  deleteSegment(id: string) {
    return this.http.delete<void>("segments/" + id);
  }
}
