export interface AssignmentApprover {
  id: number;
  assignment_id: number;
  approver_email: string;
  approver_status: string;
  approver_comment: string;
  approver_first_name: string;
  approver_last_name: string;
  author_name: string;
}
