import { CountryModel } from "./country-model";
import { DocumentModel } from "./document-model";

export interface TemplateData {
  archived: boolean;
  archived_at: string;
  countries: CountryModel[];
  created_at: string;
  description: string;
  document_id: number;
  document: DocumentModel;
  elements: [];
  id: string;
  name: string;
  updated_at: string;
  status: string;
  success?: boolean;
}
