import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { CategoryData } from "@models/category";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { CategoriesService } from "./categories.service";

export const COUNTRY_COOKIE_KEY = "offer_country_code";

@Injectable({ providedIn: "root" })
export class SearchService {
  private subject = new Subject();
  private keepAfterNavigationChange = false;
  state$ = new BehaviorSubject<object>({});
  constructor(
    private router: Router,
    private http: HttpClient,
    private categoryService: CategoriesService,
    private cookieService: CookieService
  ) {
    // clear alert message on route change
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next(null);
        }
      }
    });

    this.subject.subscribe((val: any) => {
      if (!val?.type) {
        return;
      }

      if (val.type === "search") {
        val.item = (val.item ?? "").split("?")[0];
      }

      this.state$.next({
        ...this.state$.getValue(),
        [val.type]: val.item,
      });
    });
  }

  category(category: CategoryData, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "category", item: category });
  }

  search(search: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "search", item: search });
  }

  searchSuggestions(
    searchSuggestions: string,
    keepAfterNavigationChange = false
  ) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "search-suggestions", item: searchSuggestions });
  }

  categorySiblings(siblings, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "category-siblings", item: siblings });
  }

  country(country, component, keepAfterNavigationChange = false) {
    const event = { type: "country", item: country, origin: component };

    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(event);
  }

  clearCountry(keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "clear-country", item: {} });
  }

  clearCategory(keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "clear-category", item: {} });
  }

  clearSelection(keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "clear", item: {} });
  }
  getItem(): Observable<any> {
    return this.subject.asObservable();
  }
  getSearchSugestions(searchTerm: any): Observable<any> {
    const country = this.cookieService.get(COUNTRY_COOKIE_KEY);

    return this.http.get<any>(
      environment.gateway_endpoint +
        "marketplace/offers/suggestions?search_term=" +
        searchTerm +
        "&country=" +
        country
    );
  }
}
