import { Injectable } from "@angular/core";
import { AssignmentData, CreateAssignmentData } from "@api/assignment";

@Injectable()
export class AssignmentMapperService {
  mapOne(input: any): AssignmentData {
    return {
      ...input,
      end_date: input.end_date ? new Date(input.end_date) : null,
      forecast_end_date: input.forecast_end_date
        ? new Date(input.forecast_end_date)
        : null,
      start_date: input.start_date ? new Date(input.start_date) : null,
      deadline: input.deadline ? new Date(input.deadline) : null,
      status_class_name: input.status.toLowerCase().replace(/ /g, "_"),
      home_office: input.home_office_id
        ? {
            id: input.home_office_id,
            name: input.home_office_name,
          }
        : null,
      home_company: input.home_company_id
        ? {
            id: input.home_company_id,
            name: input.home_company_name,
          }
        : null,
      home_department: input.home_department_id
        ? {
            id: input.home_department_id,
            name: input.home_department_name,
          }
        : null,
      home_segment: input.home_segment_id
        ? {
            id: input.home_segment_id,
            name: input.home_segment_name,
          }
        : null,
      host_office: input.host_office_id
        ? {
            id: input.host_office_id,
            name: input.host_office_name,
          }
        : null,
      host_company: input.host_company_id
        ? {
            id: input.host_company_id,
            name: input.host_company_name,
          }
        : null,
      host_department: input.host_department_id
        ? {
            id: input.host_department_id,
            name: input.host_department_name,
          }
        : null,
      host_segment: input.host_segment_id
        ? {
            id: input.host_segment_id,
            name: input.host_segment_name,
          }
        : null,
    };
  }

  mapMany(input: any[]): AssignmentData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareCreate(input: CreateAssignmentData) {
    return { ...input };
  }
}
