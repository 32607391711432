import { Injectable } from "@angular/core";
import { ExpenseData } from "@api/expenses/models/expenses.model";
import { CreateExpenseData } from "@api/expenses/models/create-expense.model";

@Injectable()
export class ExpensesMapperService {
  mapOne(input: any): ExpenseData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): ExpenseData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareCreate(input: CreateExpenseData) {
    return { ...input };
  }

  prepare(input: any) {
    return { ...input };
  }
}
