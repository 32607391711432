export interface DocumentModel extends Files {
  created_at: string;
  created_by: [];
  document_type: [
    {
      id: string;
      text: string;
    }
  ];
  entity_id: number;
  expiration_date: string;
  files: [Files];
}

export interface Files {
  id: number;
  file_path: string;
  name: string;
  type: string;
  size?: number;
}
