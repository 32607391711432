import { ActionReducerMap } from "@ngrx/store";

import { ActionTypes, UnsafeAction } from "./app.actions";
import {
  AppState,
  CartState,
  FavState,
  MessagesState,
  cartState,
  favState,
  messagesState,
} from "./app.state";

export function cart(state = cartState, action: UnsafeAction): CartState {
  switch (action.type) {
    case ActionTypes.SET_CART_SUCCESS: {
      return {
        ...state,
        cartItems: action.payload,
      };
    }
    case ActionTypes.SET_CART_FAILED: {
      return state;
    }
  }
  return state;
}

export function messages(
  state = messagesState,
  action: UnsafeAction
): MessagesState {
  switch (action.type) {
    case ActionTypes.SET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messagesNum: action.payload,
      };
    }
    case ActionTypes.SET_MESSAGES_FAILED: {
      return state;
    }
  }
  return state;
}

export function favourites(state = favState, action: UnsafeAction): FavState {
  switch (action.type) {
    case ActionTypes.SET_FAV_SUCCESS: {
      return {
        ...state,
        favNum: action.payload,
      };
    }
    case ActionTypes.SET_FAV_ERROR: {
      return state;
    }
  }
  return state;
}

export const getStoreCart = (state: AppState) => state.cart;
export const getStoreMessages = (state: AppState) => state.messages;
export const getTotalFav = (state: AppState) => state.favourites;

export const reducers: ActionReducerMap<AppState> = {
  cart,
  messages,
  favourites,
};
