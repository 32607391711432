import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import {
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[iframeResize]",
})
export class IframeResizeDirective implements OnInit, OnDestroy {
  private listener: () => void;
  private observer: MutationObserver;
  private prevHeight: number;
  private sameCount: number;

  get element() {
    return this.elementRef.nativeElement;
  }

  get contentHeight() {
    return this.element.contentDocument.body.scrollHeight;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.element.tagName === "IFRAME") {
      this.renderer.listen(this.element, "load", () => {
        this.prevHeight = 0;
        this.sameCount = 0;
        setTimeout(() => {
          this.setHeight();
        }, 50);
      });
    }
  }

  setFrameHeight(height: number) {
    this.renderer.setStyle(this.element, "height", `${height}px`);
  }

  setHeight() {
    if (
      this.element.contentWindow.document.body.scrollHeight !== this.prevHeight
    ) {
      this.sameCount = 0;
      this.prevHeight = this.element.contentWindow.document.body.scrollHeight;
      this.renderer.setStyle(
        this.element,
        "height",
        this.element.contentWindow.document.body.scrollHeight + "px"
      );
      setTimeout(() => {
        this.setHeight();
      }, 50);
    } else {
      this.sameCount++;
      if (this.sameCount < 2) {
        setTimeout(() => {
          this.setHeight();
        }, 50);
      }
    }
  }

  ngOnDestroy() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.observer.disconnect();
    this.listener();
  }
}
