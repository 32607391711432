import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Integration } from "../models";
import { environment } from "@environment/environment";

@Injectable()
export class AccommodationService {
  private sixtyEndpoint: string = `${environment.gateway_endpoint}accommodation/integrations/sixty`;
  private altoVitaEndpoint: string = `${environment.gateway_endpoint}accommodation/integrations/alto-vita`;

  constructor(private http: HttpClient) {}

  public integrations(): Observable<Integration[]> {
    return this.http
      .get<{ success: boolean; result: Integration[] }>(
        `${environment.gateway_endpoint}accommodation/integrations`
      )
      .pipe(
        map(({ result }) =>
          result.filter((accommodation) => accommodation.slug !== "sixty")
        )
      );
  }

  public enableSixty(): Observable<Integration> {
    return this.http
      .put<{ success: boolean; result: Integration }>(this.sixtyEndpoint, {
        enabled: true,
      })
      .pipe(map(({ result }) => result));
  }

  public enableAltoVita(): Observable<Integration> {
    return this.http
      .put<{ success: boolean; result: Integration }>(this.altoVitaEndpoint, {
        enabled: true,
      })
      .pipe(map(({ result }) => result));
  }
}
