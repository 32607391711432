import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map, shareReplay, takeLast } from "rxjs/operators";
import { WorkflowsViewModel } from "../modules/shared/models/workflow";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const marketOffersUrl = "marketplace/offers";
const marketProviderUrl = "marketplace/providers";
const docStandardsUrl = "marketplace/categories";

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 10 * 60 * 1000; // 10 mins

@Injectable()
export class MarketService {
  private cache$: Observable<any>;
  private serviceOffersUrl = "marketplace/offers";

  constructor(private http: HttpClient) {}

  getServiceCategories(): Observable<any> {
    if (!this.cache$) {
      this.cache$ = this.requestMenu().pipe(shareReplay(1));
    }
    return this.cache$.pipe(
      map((response) => response?.result?.items),
      takeLast(1)
    );
  }

  private requestMenu(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/serviceCategories/forMenu"
    );
  }

  getMarketplaceOffers(params = {}): Observable<any> {
    let url = "?";
    const keys = Object.getOwnPropertyNames(params);
    keys.forEach((key) => {
      if (typeof params[key] === "object") {
        const subkeys = Object.getOwnPropertyNames(params[key]);
        subkeys.forEach((subkey) => {
          if (key === "filters") {
            if (
              typeof params[key][subkey] === "object" &&
              params[key][subkey].length > 1
            ) {
              params[key][subkey].forEach((el) => {
                url = url + key + "[" + subkey + "]" + "[]" + "=" + el + "&";
              });
            } else {
              url =
                url +
                key +
                "[" +
                subkey +
                "]" +
                "[]" +
                "=" +
                params[key][subkey] +
                "&";
            }
          } else if (key === "range_filters") {
            url =
              url +
              key +
              "[" +
              subkey +
              "]" +
              "[min]" +
              "=" +
              params[key][subkey].min +
              "&" +
              key +
              "[" +
              subkey +
              "]" +
              "[max]" +
              "=" +
              params[key][subkey].max +
              "&";
          } else {
            url =
              url + key + "[" + subkey + "]" + "=" + params[key][subkey] + "&";
          }
        });
      } else {
        url = url + key + "=" + params[key] + "&";
      }
    });
    url = url.slice(0, -1);

    return this.http.get<any>(
      environment.gateway_endpoint + marketOffersUrl + url
    );
  }

  getServiceOfferById(id, isPreview: boolean = false): Observable<any> {
    const params = isPreview ? { preview: "true" } : {};
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceOffersUrl + "/" + id,
      { params }
    );
  }

  public getWorkflow(offerId: number): Observable<WorkflowsViewModel> {
    return this.http.get<WorkflowsViewModel>(
      environment.gateway_endpoint + `offers/offers/${offerId}/workflows`
    );
  }

  getOfferReviews(id, params) {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/" + id + "/reviews",
      {
        params: params,
      }
    );
  }

  getReviewsRatingStatistics(id) {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/" + id + "/ratingReviewsNumber"
    );
  }

  addOfferReview(id, params) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "offers/" + id + "/reviews",
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  canUserAddAReview(id) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "offers/" + id + "/can-user-add-review",
        {},
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // get provider details
  getProviderDetails(id) {
    return this.http.get<any>(
      environment.gateway_endpoint + marketProviderUrl + "/" + id
    );
  }

  // GET List of documentation standards
  getDocumentationStandardsList(category_id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        docStandardsUrl +
        "/" +
        category_id +
        "/filters"
    );
  }

  // subscribe to newsletter
  newsletterSubscribe(email) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "newsletter-subscribe",
        email,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET list of associated offers list
  getAssociatedOffers(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + `marketplace/offers/${id}/associations`
    );
  }

  // GET related services
  getRelatedServices(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "marketplace/related-services/" + id
    );
  }

  // Get Provider User Agreements

  getProviderUserAgreements(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        "entities/providers/" +
        id +
        "/user-agreements"
    );
  }
}
