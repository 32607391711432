/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { from, merge, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import * as AuthActions from "../actions/auth.actions";

@Injectable()
export class AuthEffects {
  deauthorize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.unauthenticated, AuthActions.logout),
        switchMap(() => from(this.keycloakService.logout()))
      ),
    { dispatch: false }
  );

  keycloakLogin$ = createEffect(() =>
    merge(
      this.keycloakService.keycloakEvents$.pipe(
        filter((event) => event.type === KeycloakEventType.OnReady)
      )
    ).pipe(
      switchMap(() => from(this.keycloakService.isLoggedIn())),
      switchMap((loggedIn) => {
        if (!loggedIn) {
          return of(AuthActions.loaded());
        }
        return from(this.keycloakService.getToken()).pipe(
          map((token) => AuthActions.authorized({ token }))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private keycloakService: KeycloakService
  ) {}
}
