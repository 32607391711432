import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TaskTimelineComponent } from "./task-timeline/task-timeline.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [TaskTimelineComponent],
  exports: [TaskTimelineComponent],
  imports: [CommonModule, TranslateModule.forChild()],
})
export class WorkflowModule {}
