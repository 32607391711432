import { Injectable } from "@angular/core";
import { CaseService, GroupedCaseService } from "@api/cases/models";
import { ValueList } from "@modules/shared/models/value-list.model";
import { CaseTaskData } from "@api/case-tasks";
import * as moment from "moment";

export enum StatusIcons {
  pending = "access_time",
  in_progress = "play_arrow",
  overdue = "warning",
  done = "check",
  cancelled = "close",
}

@Injectable()
export class CaseTaskMapperService {
  mapOne(input: any): CaseTaskData {
    return {
      ...input,
      deadline: input.deadline ? new Date(input.deadline) : null,

      start_date: input.start_date ? moment(input.start_date).toDate() : null,
      end_date: input.end_date ? moment(input.end_date).toDate() : null,

      forecast_start_date: moment(input.forecast_start_date).toDate(),
      forecast_end_date: moment(input.forecast_end_date).toDate(),

      statusImage: StatusIcons[input.status],
      subtasks: this.mapMany(input.subtasks || []),
    };
  }

  mapMany(input: any[]): CaseTaskData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any): CaseTaskData[] {
    return {
      ...input,
    };
  }

  groupByServices<T extends CaseTaskData>(
    tasks: Array<T>
  ): GroupedCaseService<T>[] {
    const services: ValueList<GroupedCaseService<T>> = tasks.reduce(
      (result, task) => {
        if (!result[task.case_service_id]) {
          result[task.case_service_id] = { ...task.case_service, tasks: [] };
        }
        const service: GroupedCaseService<T> = result[task.case_service_id];
        service.tasks.push(task);
        return result;
      },
      {}
    );
    return Object.values(services) as GroupedCaseService<T>[];
  }

  public fillCaseService(
    services: CaseService[],
    tasks: CaseTaskData[]
  ): CaseTaskData[] {
    const serviceMap = services.reduce((result, service) => {
      result.set(service.id, service);
      return result;
    }, new Map());
    return tasks.map((task) => {
      return {
        ...task,
        case_service: serviceMap.get(task.case_service_id),
      };
    });
  }
}
