import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RoleData } from "@api/users/models/role.model";
import { environment } from "@environment/environment";
import { CurrencyData } from "@models/currency.model";
import { Observable, of, pipe } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";

const CACHE_SIZE = 1;

@Injectable()
export class DictionariesService {
  private departmentsUrl = "entities/enum/departments";
  private industriesListUrl = "entities/enum/industries";
  private employeesNumberUrl = "entities/enum/number_of_employees";

  private cache$: Observable<any>;
  private countriesMap: Map<string, string>;
  private professionalAssociationUrl = "entities/enum/professional_association";

  constructor(private http: HttpClient) {}

  /**
   * List of all countries
   */
  getCountryList() {
    if (!this.cache$) {
      this.cache$ = this.requestCountries().pipe(
        pipe(
          map((data) => {
            data.result = data.result.map((country) => ({
              ...country,
              iso: country.iso ? country.iso : country.country_code,
            }));
            return data.result;
          }),
          shareReplay(CACHE_SIZE)
        )
      );
    }
    return this.cache$;
  }

  getCountryMap(): Observable<Map<string, string>> {
    if (this.countriesMap) {
      return of(this.countriesMap);
    }
    return this.getCountryList().pipe(
      map((countries) => {
        return countries.reduce(
          (countriesMap: Map<string, string>, country) =>
            countriesMap.set(country.country_code, country.name),
          new Map<string, string>()
        );
      }),
      tap((countries) => (this.countriesMap = countries))
    );
  }

  private requestCountries(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "countries");
  }

  /**
   * List of all cities in a country
   */
  getCities(country, search = "") {
    return this.http.get<any>(environment.gateway_endpoint + "cities", {
      params: {
        q: search,
        country_code: country,
      },
    });
  }

  /**
   * List of all countries
   */
  getIndustriesList(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.industriesListUrl
    );
  }

  /**
   * Get employees number
   */
  getEmployeesNumber(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.employeesNumberUrl
    );
  }

  /**
   * List of departments
   */
  getDepartments(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.departmentsUrl
    );
  }

  /**
   * List of expat statuses
   */
  getEntityStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/entity_statuses"
    );
  }

  /**
   * List of expat statuses
   */
  getOrderStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "orders/statuses");
  }

  /**
   * List of company types
   */
  getCompanyTypes(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/company_types"
    );
  }

  /**
   * List of industries
   */
  getIndustries(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/industries"
    );
  }

  /**
   * List of company sizes by number of employees
   */
  getCompanySizes(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/number_of_employees"
    );
  }

  getUserRoles(type): Observable<RoleData> {
    return this.http.get<RoleData>(
      environment.gateway_endpoint + type + "/roles"
    );
  }

  intCountryPhonePhone(code) {
    const numberPattern = /\d+/g;
    return code.match(numberPattern).join("");
  }

  getPhonePrefix(code) {
    if (!code) {
      return null;
    }
    return "(+" + this.intCountryPhonePhone(code) + ")";
  }

  // GET currencies for invoice upload
  getCurrencies(): Observable<CurrencyData[]> {
    return this.http
      .get<CurrencyData[]>(environment.gateway_endpoint + "entities/currencies")
      .pipe(
        map((response: any) => {
          return response as CurrencyData[];
        })
      );
  }

  getprofessionalAssociation(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.professionalAssociationUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }
}
