import { DOCUMENT, ViewportScroller } from "@angular/common";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Event, NavigationEnd, Router, Scroll } from "@angular/router";
import { SvgRegistryService } from "@services/svg-registry.service";
import { WindowResizeHelperService } from "@services/window-resize-helper.service";
import { CookieService } from "ngx-cookie-service";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { filter, pairwise, take } from "rxjs/operators";
export const COOKIE_CONSENT_COOKIE_KEY = "cookieConsent";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "xpath.global - marketplace";
  innerWidth;
  xs;

  constructor(
    private router: Router,
    private windowResizeHelper: WindowResizeHelperService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private svgRegistryService: SvgRegistryService,
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    private viewportScroller: ViewportScroller
  ) {
    this.onResize();
    this.windowResizeHelper.sendData(this.xs);
    this.svgRegistryService.init();
    this.handleScrollOnNavigation();
  }

  ngOnInit() {
    this.ccService.statusChange$.pipe(take(1)).subscribe((event) => {
      this.cookieService.set(COOKIE_CONSENT_COOKIE_KEY, event.status, 365);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route) => {
        let primaryRoute = route["url"].split("/")[1];
        primaryRoute = primaryRoute.split("?")[0];
        primaryRoute =
          primaryRoute.charAt(0).toUpperCase() + primaryRoute.slice(1);

        this.titleService.setTitle(primaryRoute);
      });
    this.windowResizeHelper.sendData(this.xs);
  }

  @HostListener("window:resize")
  onResize() {
    this.innerWidth = this.document.defaultView.innerWidth || 1920;

    this.xs = this.innerWidth <= 992;

    this.windowResizeHelper.sendData(this.xs);
  }

  private handleScrollOnNavigation(): void {
    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise()
      )
      .subscribe((e: Scroll[]) => {
        const previous = e[0];
        const current = e[1];
        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // Check if routes match, or if it is only a query param change
          if (
            this.getBaseRoute(previous.routerEvent.url) !==
            this.getBaseRoute(current.routerEvent.url)
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split("?")[0];
  }
}
