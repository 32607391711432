import { Injectable } from "@angular/core";
import { LOCALE_COOKIE_KEY } from "@modules/shared/components/translate/translate.component";
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class TranslateEffects {
  setCart$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap(() => {
          this.translate.addLangs(["en"]);
          this.translate.setDefaultLang("en");
          const browserLang = this.translate.getBrowserLang()
            ? this.translate.getBrowserLang()
            : "en";
          this.translate.use(browserLang.match(/en/) ? browserLang : "en");
          const lang = this.cookieService.get(LOCALE_COOKIE_KEY);
          if (lang) {
            this.translate.use(lang);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {}
}
