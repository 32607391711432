import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccommodationService } from "./services/accommodation.service";
import { SixtyService } from "./services/sixty.service";
import { AltoVitaService } from "./services/alto-vita.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AccommodationService, SixtyService, AltoVitaService],
})
export class AccommodationModule {}
