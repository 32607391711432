import { createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TaskActions } from "../actions";
import { CaseTaskData } from "../models";

export const tasksFeatureKey = "tasks";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends EntityState<CaseTaskData> {
  // additional entities state properties
  selectedTaskId: null | number;
  updating: boolean;
  creating: boolean;
  fetching: boolean;
}

export const adapter: EntityAdapter<CaseTaskData> =
  createEntityAdapter<CaseTaskData>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedTaskId: null,
  updating: false,
  fetching: false,
  creating: false,
});

export const reducer = createReducer(
  initialState,
  on(TaskActions.addTask, (state, action) =>
    adapter.addOne(action.task, state)
  ),
  on(TaskActions.selectTask, (state, { taskId }) => ({
    ...state,
    selectedTaskId: taskId,
  })),
  on(TaskActions.fetchTaskSuccess, (state, action) =>
    adapter.setOne(action.task, state)
  ),
  on(TaskActions.updateTask, (state, action) =>
    adapter.updateOne(action.task, state)
  ),
  on(TaskActions.upsertTask, (state, action) =>
    adapter.upsertOne(action.task, state)
  ),
  on(TaskActions.addTasks, (state, action) =>
    adapter.addMany(action.tasks, state)
  ),
  on(TaskActions.updateTasks, (state, action) =>
    adapter.updateMany(action.tasks, state)
  ),
  on(TaskActions.upsertTasks, (state, action) =>
    adapter.upsertMany(action.tasks, state)
  ),
  on(TaskActions.deleteTask, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(TaskActions.deleteTasks, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(TaskActions.loadTasks, (state, action) =>
    adapter.setAll(action.tasks, state)
  ),
  on(TaskActions.clearTasks, (state) => adapter.removeAll(state)),
  // fetch one mid
  on(TaskActions.fetchTaskSuccess, (state, action) =>
    adapter.upsertOne(action.task, state)
  ),
  // fetch multiple tasks
  on(TaskActions.fetchTasks, (state, action) => ({
    ...state,
    fetching: true,
  })),
  on(TaskActions.fetchTasksSuccess, (state, action) =>
    adapter.upsertMany(action.tasks, { ...state, fetching: false })
  ),
  on(TaskActions.fetchTasksError, (state, action) => ({
    ...state,
    fetching: false,
  })),
  // update
  on(TaskActions.requestUpdateTask, (state, action) => ({
    ...state,
    updating: true,
  })),
  on(TaskActions.requestUpdateTaskSuccess, (state, action) =>
    adapter.updateOne(
      { id: action.task.id, changes: action.task },
      {
        ...state,
        updating: false,
      }
    )
  ),
  on(TaskActions.requestUpdateTaskError, (state, action) => ({
    ...state,
    updating: false,
  })),
  // create
  on(TaskActions.requestCreateTask, (state, action) => ({
    ...state,
    creating: true,
  })),
  on(TaskActions.requestCreateTaskSuccess, (state, action) =>
    adapter.addOne(action.task, {
      ...state,
      creating: false,
    })
  ),
  on(TaskActions.requestCreateTaskError, (state, action) => ({
    ...state,
    creating: false,
  }))
);

export const getSelectedTaskId = (state: State) => state.selectedTaskId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of task ids
export const selectTaskIds = selectIds;

// select the dictionary of task entities
export const selectTaskEntities = selectEntities;

// select the array of tasks
export const selectAllTasks = selectAll;

// select the total task count
export const selectTaskTotal = selectTotal;
