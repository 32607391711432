import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatsModule } from "@api/stats";
import { DocumentsModule } from "./documents/documents.module";
import { CasesModule } from "@api/cases";
import { CaseTasksModule } from "@api/case-tasks";
import { InternalProcessesModule } from "@api/internal-processes/internal-processes.module";
import { ExpensesModule } from "./expenses/expenses.module";
import { PermissionsService } from "./users/services/permissions.service";
import { ResourcePermissionMapperService } from "./users/services/resource-permission-mapper.service";
import { PermissionMapperService } from "./users/services/permission-mapper.service";
import { TemplatesModule } from "@api/templates";
import { RemindersModule } from "./reminders/reminders.module";
import { AssignmentsModule } from "@api/assignment";
import { AccountModule } from "./account/account.module";
import { AuthenticationModule } from "./authentication";
import { IonicStorageModule } from "@ionic/storage-angular";
import { environment } from "@environment/environment";
import {
  BACKEND_API_HTTP_INTERCEPTORS,
  BackendHttpClient,
} from "./http/backend-http-client";
import { BASE_API_URL } from "./http/base-api-url.token";
import { AuthInterceptor } from "./http/interceptors/auth.interceptor";
import { BaseUrlInterceptor } from "./http/interceptors/base-url.interceptor";
import { DefaultHeadersInterceptor } from "./http/interceptors/default-headers.interceptor";
import { StorageService } from "./storage/storage.service";
import { AccommodationModule } from "./accommodation/accommodation.module";
import { KomboService } from "./integrations/services/kombo.service";
import { MilestonesModule } from "@api/milestones";
import { RfqModule } from "@api/rfq/rfq.module";
import { TeamsModule } from "@api/teams";
import { WorkflowModule } from "@modules/shared/modules/workflow";
import { GetArrayInterceptor } from "./http/interceptors/get-array.interceptor";
import { ErrorInterceptor } from "./http/interceptors/error.interceptor";
import { ReportsModule } from "@api/reports/reports.module";
import { IntlModule } from "./intl/intl.module";
import { DepartmentModule } from "./department/department.module";
import { SegmentModule } from "./segment/segment.module";

@NgModule({
  imports: [
    CommonModule,
    CasesModule,
    CaseTasksModule,
    DocumentsModule,
    InternalProcessesModule,
    ExpensesModule,
    TemplatesModule,
    RemindersModule,
    AssignmentsModule,
    WorkflowModule,
    AccountModule,
    AuthenticationModule,
    AuthenticationModule.forRoot(),
    IonicStorageModule.forRoot({
      name: "__xpath",
    }),
    AccommodationModule,
    StatsModule,
    MilestonesModule,
    RfqModule,
    TeamsModule,
    IntlModule,
    ReportsModule,
    DepartmentModule,
    SegmentModule,
  ],
  providers: [
    KomboService,
    PermissionsService,
    ResourcePermissionMapperService,
    StorageService,
    { provide: BASE_API_URL, useValue: environment.gateway_endpoint },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: GetArrayInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    BackendHttpClient,
  ],
})
export class ApiModule {
  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [PermissionMapperService],
    };
  }
}
