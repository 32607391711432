import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { Observable, merge, of } from "rxjs";
import { map, pairwise, tap } from "rxjs/operators";
import { BulkInternalProcessUpdateModel } from "../models/bulk-internal-process-update.model";
import {
  InternalProcess,
  InternalProcessData,
  InternalProcessModel,
} from "../models/internal-process.model";
import { PageData } from "@modules/shared/models/page.model";
import { WorkflowModel } from "@models/workflow";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class InternalProcessesService {
  private internalProcessUrl = "offers/offers";
  private offProvider = "offers/provider";
  private offerHistory = "history/offer";

  offerDetails;

  constructor(private http: HttpClient) {}

  getInternalProcesses(params): Observable<InternalProcessModel> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.internalProcessUrl,
      {
        params: {
          ...params,
          exclude_id: params?.exclude_id ? params.exclude_id : ";",
        },
      }
    );
  }

  getInternalProcessesList(params): Observable<PageData<InternalProcessData>> {
    return merge(
      of({ items: [], filters: [], total: 0 }),
      this.http
        .get<any>(environment.gateway_endpoint + this.internalProcessUrl, {
          params: {
            ...params,
            exclude_id: params.exclude_id ? params.exclude_id : ";",
          },
        })
        .pipe(
          map((response) => {
            delete response.result.filters;
            return response.result;
          })
        ),
      this.http
        .get<any>(environment.gateway_endpoint + this.internalProcessUrl, {
          params: {
            ...params,
            include: "filters",
            per_page: 1,
            exclude_id: params.exclude_id ? params.exclude_id : ";",
          },
        })
        .pipe(map((response) => ({ filters: response.result.filters })))
    ).pipe(
      pairwise(),
      map(([prev, curr]) => ({ ...prev, ...curr }))
    );
  }

  getInternalProcessById(id): Observable<InternalProcessModel> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + this.internalProcessUrl + "/" + id
      )
      .pipe(
        tap((data) => {
          this.offerDetails = data.result;
        })
      );
  }

  createInternalProcess(offer: Partial<InternalProcess>): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + this.internalProcessUrl,
        offer,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  copyWorkflow(offerId, sourceOfferId: number): Observable<WorkflowModel[]> {
    return this.http
      .post<{ result: WorkflowModel[] }>(
        environment.gateway_endpoint +
          this.internalProcessUrl +
          `/${sourceOfferId}/copy-workflow`,
        offerId,
        httpOptions
      )
      .pipe(map(({ result }) => result));
  }

  updateInternalProcesses(
    offer: Partial<InternalProcess>,
    id
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.internalProcessUrl + "/" + id,
        offer,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Bulk
  updateBulk(update: BulkInternalProcessUpdateModel): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + this.internalProcessUrl + "/bulk",
        update,
        httpOptions
      )
      .pipe(map((response) => response.result));
  }

  // Update Status Bulk
  updateStatusBulk(status_id, offer_ids): Observable<any> {
    return this.http.patch<any>(
      environment.gateway_endpoint + this.internalProcessUrl,
      { status_id: status_id, offer_ids: offer_ids },
      httpOptions
    );
  }

  // Copy offer
  copyOffer(offerId): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        this.internalProcessUrl +
        "/" +
        offerId +
        "/duplicate",
      {},
      httpOptions
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.internalProcessUrl +
        "/status-transitions"
    );
  }

  // Upload by doc type
  uploadImage(id, files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", files[0], files[0].name);
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "offers/offers/" +
          id +
          "/setPrimaryImage",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadBulkOffers(provider_id, category_id, obj): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    formData.append("provider_id", provider_id);
    formData.append("category_id", category_id);

    return this.http
      .post<any>(environment.gateway_endpoint + "offers/import", formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  downloadExcel(provider_id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.offProvider +
        "/" +
        provider_id +
        "/download-offers-zip",
      { responseType: "blob" as const }
    );
  }

  getBundleServiceOffersById(id): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "bundles/" + id);
  }

  createBundleServiceOffers(offer: Partial<InternalProcess>): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + "bundles", offer, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getOffersReviews(id, params): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "offers/" + id + "/reviews",
      {
        params: params,
      }
    );
  }

  addOfferReview(id, params) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "offers/" + id + "/reviews",
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET Offer history
  getOfferHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.offerHistory + "/" + id,
      {
        params: params,
      }
    );
  }

  calculateDiscount(oldP, newP) {
    return Math.round(((oldP - newP) / oldP) * 100);
  }

  /**
   * Export filtered offers to CSV
   * @param params
   */
  export(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + this.internalProcessUrl + "/export",
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  uploadDocument(offerId, file): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    return this.http
      .post<any>(
        environment.gateway_endpoint + "offers/offers/" + offerId + "/docs",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }
}
