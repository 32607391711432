import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepartmentService } from "./services/department.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [DepartmentService],
})
export class DepartmentModule {}
