import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SegmentService } from "./services/segment.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [SegmentService],
})
export class SegmentModule {}
